import api from "./api";
import apiMP from "./apimp";
import Utils from "@/common/Utils";

class TimelineService {
  insertTimeline(params){
    return api
      .post("/timeline/insertTimeline", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  updateTimeline(params){
    return api
      .post("/timeline/updateTimeline", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  insertTimelineImage(params){
    return apiMP
      .post("/timeline/insertTimelineImage", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getTimelineImageList(params){
    return api
      .post("/timeline/getTimelineImageList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getTimelineList(params){
    return api
      .post("/timeline/getTimelineList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getTimeline(params){
    return api
      .post("/timeline/getTimeline", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getTimelineSummary(params){
    return api
      .post("/timeline/getTimelineSummary", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  insertTimelineComment(params){
    return apiMP
      .post("/timeline/insertTimelineComment", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  deleteTimelineComment(params){
    return api
      .post("/timeline/deleteTimelineComment", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //데이터유효성 변경
  updateTimelineExclude(params) {
    return api
      .post("/timeline/updateTimelineExclude", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new TimelineService();
