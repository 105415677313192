<template>
  <div class="tit-group">
    <h2 class="tit">언어 설정</h2>
  </div>
  <div class="content-box">

    <div class="sort-group">
      <div class="sort-row">
        <div class="wl">
          <c-btn02 :btnText="'업로드'"/>
          <c-btn02 :btnText="'다운로드'"/>
        </div>
        <div class="wr">
          <Select v-bind="versionSelect" v-model="versionModel"/>
          <!-- <c-btn01 :btnText="'언어추가'"/> -->
          <c-btn01 :btnText="'저장'" @click="dialogSaveModel=true"/>

        </div>
      </div>
    </div>
    <div class="tbl-group02">
      <table>
        <colgroup>
          <col style="width:10%">
          <col>
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:20%">
        </colgroup>
        <thead>
        <tr>
          <th>
            <div>모듈</div>
          </th>
          <th>
            <div class="flex-l">항목</div>
          </th>
          <th>
            <div class="flex-r">
              <c-tooltip :TooltipText="'언어 변경 버튼에서 노출되는 명칭입니다.'"/>
              <c-Input v-model="titles[0]" :name="'title0'"/>
              </div>
          </th>
          <th>
            <div class="flex-r">
              <c-Input v-model="titles[1]" :name="'title1'"/>
            </div>
          </th>
          <th>
            <div class="flex-r">
              <c-Input v-model="titles[2]" :name="'title2'"/>
            </div>
          </th>
          <th>
            <div class="flex-r">
              <c-Input v-model="titles[3]" :name="'title3'"/>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in items" :key="i">
          <!--<td rowspan="2">-->
          <td>
            <div class="bold">{{ item.module }}</div>
          </td>
          <td>
            <div class="flex-l bold">{{item.title}}</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input v-model="item.contentA" :name="'contentA'"/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input v-model="item.contentB" :name="'contentB'"/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input v-model="item.contentC" :name="'contentC'"/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input v-model="item.contentD" :name="'contentD'"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  <!--변경정보-->
  <c-dialog v-model="dialogSaveModel"
            v-bind="dialogSave"
            @close="dialogSaveModel=false"
  />
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useCommonStore } from "@/store/common";
import LanguageService from "@/services/language.service";
import utils from "@/common/Utils";
import _ from 'lodash';

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const versionSelect:any = ref({})
const versionModel = ref({})
const dialogSaveModel = ref(false)
const items = ref([])
const titles = ref([])

//ui
const dialogSave= {
  dialogTit: '알림',
  dialogText: '저장하면 현재 기준으로 신규버전이 생성됩니다.',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}

onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    versionSelect.value.items=[{name: '1-admin(2023.03.02)', value: '1'}, {name: '2-admin(2023.03.02)', value: '2'}, {name: '3-admin(2023.03.02)', value: '3'}]
  }
  else{
    //언어팩 확인
    const companyIdx = localStorage.getItem('companyIdx')
    LanguageService.getLanguageList({companyIdx:companyIdx, lang:null}).then(
      (response) => {
        items.value = response.result.languages
        let versionList = response.result.versions
        _.forEach(versionList, function (item) {
          if(item.version == 0)
            return
          else
            versionSelect.value.items.push({name: item.version+'-'+item.memberId+'('+item.regDateFormat+')', value: item.version})
        })
        if(versionSelect.value.length > 0)
          versionModel.value = versionSelect.value.items[0]
        titles.value = response.result.titles
      },
      (error) => {
        utils.log((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      }
    )
  }
})
</script>
<style lang="scss" scoped>
.tbl-group .v-table .v-table__wrapper > table > tbody > tr > td:nth-child(2){width:30%;}
</style>
