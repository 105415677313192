import api from "./api";
import Utils from "@/common/Utils";

class LanguageService {
  getLanguageList(params) {
    return api
      .post("/language/getLanguageList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new LanguageService();
