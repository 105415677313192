<template>
  <div class="sort-group ty01">
    <div class="sort-row">
      <div class="wl">
        <div class="date-sort">
          <c-inputSearch v-bind="inputSearch" v-model="searchModel" @search-word="onClickSearch" />
          <c-InputDate v-model="startDate" :enableTimePicker="false" readonly="" />
          <div class="txt02">~</div>
          <c-InputDate v-model="endDate" :enableTimePicker="false" readonly=""/>
        </div>
        <div class="date-sort">
          <c-btn02 :btnText="'일주'" class="ty01" @click="onClickDate('week')"/>
          <c-btn02 :btnText="'한달'" class="ty01" @click="onClickDate('month')"/>
        </div>
      </div>
    </div>
    <div class="sort-row">
      <div class="wl">
        <Select v-bind="deptSelect" v-model="deptModel"/>
        <Select v-bind="posSelect" v-model="posModel"/>
        <Select v-bind="shiftSelect" v-model="shiftModel"/>
        <Select v-bind="monitoringSelect" v-model="monitoringModel"/>
        <Select v-bind="excludeSelect" v-model="excludeModel"/>
      </div>
    </div>
  </div>
  <div class="scrollbar">
    <div class="tbl-group size-custom18">
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        return-object
      >
        <template v-slot:item.isExclude="{ item }" >
          <div class="check-group ty03">
            <v-checkbox :model-value="item.isExclude"
                        @click="onChangeCheck(item.idx, $event)"
                        true-value="Y" false-value="N"
            >
            </v-checkbox>
          </div>
        </template>
        <template v-slot:item.situation="{ item }" >
          <div class="tal">
            {{item.situation}}
          </div>
        </template>
        <template v-slot:item.memberInfo="{ item }" >
          <div class="txt-tag">
            {{item.memberName+'/'+item.deptName+'/'+item.posName}}
          </div>
        </template>
        <template v-slot:item.targetInfo="{ item }" >
          <div class="txt-tag">
            {{utils.isEmpty(item.targetMemberName)?'':item.targetMemberName+'/'+item.targetDeptName}}
          </div>
        </template>
        <template v-slot:item.detailView="{ item }">
          <div class="flex-c">
            <BtnIcon class="btn-ico-search" @click="onClickDetail(item.idx)"/>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-divider class="mb-4"></v-divider>
  </div>
  <DialogMonitoringDetail
    v-model="detailDialogModel"
    @close="detailDialogModel=false"/>
  <v-snackbar v-model="snackbar" :timeout="3000"> {{ snackText }} </v-snackbar>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import BbsService from "@/services/bbs.service";
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//bind
const snackbar = ref(false)
const snackText = ref('')
let viewIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value
const detailDialogModel = ref(false)
const deptSelect = ref({items: [{name:'부서', value:''}]})
const posSelect = ref({items: [{name:'직책', value:''}]})
const shiftSelect = ref({items: [{name: '교대조', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]})
const monitoringSelect = ref({items: [{name:'모니터링(전체)', value:''}]})
const excludeSelect = ref({items: [{name:'통계', value:''}, {name:'통계제외', value:'Y'},{name:'통계포함', value:'N'}]})
const startDate = ref('')
const endDate = ref('')
const deptModel = ref({name:'부서', value:''})
const posModel = ref({name:'직책', value:''})
const shiftModel = ref({name: '교대조', value: ''})
const monitoringModel = ref({name:'모니터링(전체)', value:''})
const excludeModel = ref({name:'통계', value:''})
const searchModel = ref(null)
const headers = ref([
  { title: '통계제외', key: 'isExclude' },
  { title: '안전대화/칭찬', key: 'situation' },
  { title: '작성자', key: 'memberInfo'},
  { title: '시정조치대상', key: 'targetInfo' },
  { title: '작성일시', key: 'regDateFormat' },
  { title: '상세보기', key: 'detailView' },
])
const items = ref([])
let firstLoadingDone = false

//ui
const inputSearch = {
  type: 'text',
  placeholder: '검색어를 입력하세요. (,로 다중검색)',
}

//watch
watch(startDate, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(endDate, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(deptModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(posModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(shiftModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(monitoringModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(excludeModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})


//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        situation:'안전대화 제목이 들어갑니다1 안전대화 제목이 들어갑니다1 안전대화 제목이 들어갑니다1',
        memberInfo: '김동임',
        targetInfo: '김동임',
        detailView: '23',
        regDateFormat: '2024.03.01 06:32:33',
      },
      {
        situation:'안전대화 제목이 들어갑니다1',
        memberInfo: '김동임',
        targetInfo: '김동임',
        detailView: '23',
        regDateFormat: '2024.03.01 06:32:33',
      },
      {
        situation:'안전대화 제목이 들어갑니다1',
        memberInfo: '김동임',
        targetInfo: '김동임',
        detailView: '23',
        regDateFormat: '2024.03.01 06:32:33',
      },
      {
        situation:'안전대화 제목이 들어갑니다1',
        memberInfo: '김동임',
        targetInfo: '김동임',
        detailView: '23',
        regDateFormat: '2024.03.01 06:32:33',
      },
    ]
  }
  else {
    if (viewIdx) {
      //본인부서에 해당하는 모니터링, 부서, 맴버정보
      BbsService.getMonitoringFilter({}).then(
        (response) => {
          //모니터링필터
          let monitoringList = response.result.monitoringList
          _.forEach(monitoringList, function (item) {
            monitoringSelect.value.items.push({name: item.monitoringTitle, value: item.idx})
            if (viewIdx == item.idx)
              monitoringModel.value = {name: item.monitoringTitle, value: item.idx}
          })
          //부서필터
          let deptList = response.result.deptList
          _.forEach(deptList, function (item) {
            deptSelect.value.items.push({name: item.treeName, value: item.idx})
          })
          //직책필터
          let posList = response.result.posList
          _.forEach(posList, function (item) {
            posSelect.value.items.push({name: item.treeName, value: item.idx})
          })
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
      firstLoadingDone = true
    }
  }
})

function onClickSearch(){
  const user = JSON.parse(localStorage.getItem('user'))
  let params = {
    keyword: searchModel.value,
    startDate:startDate.value,
    endDate:endDate.value,
    companyIdx:user.companyIdx,
    isExclude:excludeModel.value['value'],
    monitoringIdx:monitoringModel.value['value'],
    deptIdx:deptModel.value['value'],
    posIdx:posModel.value['value'],
    shiftYn:shiftModel.value['value'],
    excludeType:'2'  //타입 1:모니터링 2:안전대화
  }

  TimelineService.getTimelineList(params).then(
    (response) => {
      //타임라인
      items.value = response.result
      _.forEach(items.value, function(val, key) {
        val.typeText = val.type=='1'?'교정적 안전대화':'지지적 안전대화'
        val.actionYnText = val.actionYn=='Y'?'필요':'불필요'
        val.targetText = '없음'
        if(val.targetType == '2') val.target = val.targetDept
        else if(val.targetType == '3') val.target = val.targetMember
        val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(val.goalDate).format('YYYY-MM-DD')
        val.statusText = '준비'
        if(val.status == '2')
          val.statusText = '진행중'
        else if(val.status == '3')
          val.statusText = '보류'
        else if(val.status == '4')
          val.statusText = '완료'
      })
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickDate(type){
  const end = dayjs().format('YYYY-MM-DD')
  let start = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  if(type == 'month')
    start = dayjs().subtract(1, 'month').format('YYYY-MM-DD')

  startDate.value = start.toString()
  endDate.value = end.toString()
}

function onChangeCheck(idx, event){
  let user = JSON.parse(localStorage.getItem('user'))
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    excludeType:'2',
    idx:idx,
    isExclude:event.target.checked?'Y':'N',
    companyIdx:companyIdx
  }

  TimelineService.updateTimelineExclude(params).then(
    (response) => {
      if(response.success){
        snackText.value = 'updated'
        snackbar.value = true
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickDetail(idx){
  storeRef.popupIdx.value = idx
  storeRef.viewIdx.value = viewIdx
  detailDialogModel.value=true
}
</script>


<style lang="scss">
</style>
