import api from "./api";
import Utils from "@/common/Utils";

class AlarmService {
  getAlarm(params) {
    return api
      .post("/alarm/getAlarm", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getAlarmList(params) {
    return api
      .post("/alarm/getAlarmList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  updateAlarmCheck(params) {
    let apiUrl = '/alarm/updateAlarmCheck'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new AlarmService();
