/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
//import { createRouter, createWebHistory } from 'vue-router/auto'
//import { setupLayouts } from 'virtual:generated-layouts'
import { createMemoryHistory, createWebHistory, createRouter } from 'vue-router'
import layoutLogin from "@/layouts/layoutLogin.vue";
import layoutGnb from "@/layouts/layoutGnb.vue";
import layoutGnbLnb from "@/layouts/layoutGnbLnb.vue";
import login from "@/pages/login.vue";
import customerManagement from "@/pages/customerManagement.vue";
import groupManagement from "@/pages/groupManagement.vue";
import organizationalManagement from "@/pages/organizationalManagement.vue";
import languageSetting from "@/pages/languageSetting.vue";
import actionManagement from "@/pages/actionManagement.vue";
import alarm from "@/pages/alarm.vue";
import safetyCalender2 from "@/pages/safetyCalender2.vue";
import safetyCalender from "@/pages/safetyCalender.vue";
import myBoardDetail from "@/pages/myBoardDetail.vue";
import myBoard from "@/pages/myBoard.vue";
import safetyLeadershipRun from "@/pages/safetyLeadershipRun.vue";
import safetyLeadershipManagementDetail from "@/pages/safetyLeadershipManagementDetail.vue";
import safetyLeadershipManagement from "@/pages/safetyLeadershipManagement.vue";
import safetyLeadershipAMListDetail from "@/pages/safetyLeadershipAMListDetail.vue";
import safetyLeadershipAMList from "@/pages/safetyLeadershipAMList.vue";
import safetyLeadership from "@/pages/safetyLeadership.vue";
import monitoringManagementListDetail from "@/pages/monitoringManagementListDetail.vue";
import monitoringManagementList from "@/pages/monitoringManagementList.vue";
import monitoring from "@/pages/monitoring.vue";
import actionManagementListDetail from "@/pages/actionManagementListDetail.vue";
import actionManagementList from "@/pages/actionManagementList.vue";
import actionManagementAll from "@/pages/actionManagementAll.vue";
import totalReport from "@/pages/totalReport.vue";
import notFound from "@/pages/notFound.vue";

import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import utils from "@/common/Utils";
import AdminService from "@/services/admin.service";
import LanguageService from "@/services/language.service";
import AlarmService from "@/services/alarm.service";
import _ from 'lodash';

/*
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  extendRoutes: setupLayouts,
})
*/
const routes = [
  { path: '', component: layoutLogin,
    children:[
      {path:'', component:login}
    ]
  },
  { path: '/', component: layoutLogin,
    children:[
      {path:'', component:login}
    ]
  },
  { path: '/login', component: layoutLogin,
    children:[
      {path:'', component:login}
    ]
  },
  { path: '/clientManagement', component: layoutGnb,
    children:[
      {path:'', component:customerManagement}
    ]
  },
  { path: '/groupManagement', component: layoutGnb,
    children:[
      {path:'', component:groupManagement}
    ]
  },
  { path: '/organizationalManagement/:tab/:page', component: layoutGnbLnb,
    children:[
      {path:'', component:organizationalManagement}
    ]
  },
  { path: '/organizationalManagement/:tab/:page/:idx', component: layoutGnbLnb,
    children:[
      {path:'', component:organizationalManagement}
    ]
  },
  { path: '/languageSetting', component: layoutGnbLnb,
    children:[
      {path:'', component:languageSetting}
    ]
  },
  { path: '/actionManagement', component: layoutGnbLnb,
    children:[
      {path:'', component:actionManagement}
    ]
  },
  { path: '/actionManagementAll', component: layoutGnbLnb,
    children:[
      {path:'', component:actionManagementAll}
    ]
  },
  { path: '/actionManagementList', component: layoutGnbLnb,
    children:[
      {path:'', component:actionManagementList}
    ]
  },
  { path: '/actionManagementListDetail/:idx', component: layoutGnbLnb,
    children:[
      {path:'', component:actionManagementListDetail}
    ]
  },
  { path: '/monitoring/:tab/:idx', component: layoutGnbLnb,
    children:[
      {path:'', component:monitoring}
    ]
  },
  { path: '/monitoringManagementList', component: layoutGnbLnb,
    children:[
      {path:'', component:monitoringManagementList}
    ]
  },
  { path: '/monitoringManagementListDetail/:idx', component: layoutGnbLnb,
    children:[
      {path:'', component:monitoringManagementListDetail}
    ]
  },
  { path: '/safetyLeadership', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadership}
    ]
  },
  { path: '/safetyLeadershipAMList', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipAMList}
    ]
  },
  { path: '/safetyLeadershipAMListDetail', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipAMListDetail}
    ]
  },
  { path: '/safetyLeadershipManagement', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipManagement}
    ]
  },
  { path: '/safetyLeadershipManagementDetail', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipManagementDetail}
    ]
  },
  { path: '/safetyLeadershipRun', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipRun}
    ]
  },
  { path: '/myBoard', component: layoutGnbLnb,
    children:[
      {path:'', component:myBoard}
    ]
  },
  { path: '/myBoardDetail/:idx', component: layoutGnbLnb,
    children:[
      {path:'', component:myBoardDetail}
    ]
  },
  { path: '/safetyCalender', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyCalender}
    ]
  },
  { path: '/safetyCalender2', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyCalender2}
    ]
  },
  { path: '/totalReport', component: layoutGnbLnb,
    children:[
      {path:'', component:totalReport}
    ]
  },
  { path: '/alarm', component: layoutGnbLnb,
    children:[
      {path:'', component:()=> import('@/pages/alarm.vue')}
    ]
  },
  { path: '/noAuth', component: layoutLogin,
    children:[
      {path:'', component:()=> import('@/pages/noAuth.vue')}
    ]
  },
  { path: '/:catchAll(.*)', component: layoutLogin,
    children:[
      {path:'', component: notFound}
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  utils.log('router.beforeEach:'+to.path)
  if(!(import.meta.env.MODE == 'development')) {
    //언어팩 확인
    let lang = localStorage.getItem('lang')
    const companyIdx = localStorage.getItem('companyIdx')
    if(utils.isEmpty(lang))
      lang = 'A'
    /* TODO 코딩중
    LanguageService.getLanguageList({companyIdx:companyIdx, lang}).then(
      (response) => {
        const langVersion = localStorage.getItem('langVersion')
        const serverLangVersion = response.result.versions[response.result.versions.length-1]
        if(utils.isEmpty(langVersion) || langVersion < serverLangVersion){
          localStorage.setItem('langVersion', serverLangVersion)

        }
      },
      (error) => {
        utils.log((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      }
    )
     */

    //로그인 권한 확인
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    const user = JSON.parse(localStorage.getItem('user')!)
    if (authRequired && !user) {
      next('/login')
    } else {
      //관리페이지 권한확인 actionManagementListDetail, monitoringManagementListDetail
      if(to.path.includes('/organizationalManagement') || to.path.includes('/actionManagementList') || to.path.includes('/monitoringManagementList')){
        //최고관리자는 모두 통과
        if(user.roles == 'ADMIN1')
          next()
        else {
          AdminService.getAdmin({memberIdx: user.memberIdx}).then(
            (response) => {
              let adminInfo = response.result
              if (adminInfo) {
                localStorage.setItem("admin", JSON.stringify(adminInfo));
                let isAuth = true
                if (to.path.includes('/organizationalManagement/1/list') && adminInfo.authCompany < 1)
                  isAuth = false
                else if (to.path.includes('/organizationalManagement/2/list') && adminInfo.authMember < 1)
                  isAuth = false
                else if (to.path.includes('/organizationalManagement/2/view') && adminInfo.authMember < 2)
                  isAuth = false
                else if (to.path.includes('/organizationalManagement/3/list') && adminInfo.authAdmin < 1)
                  isAuth = false
                else if (to.path.includes('/organizationalManagement/3/view') && adminInfo.authAdmin < 2)
                  isAuth = false
                else if (to.path.includes('/organizationalManagement/4/list') && adminInfo.authBoard < 1)
                  isAuth = false
                else if (to.path.includes('/organizationalManagement/4/view') && adminInfo.authBoard < 2)
                  isAuth = false
                else if ((to.path.includes('/actionManagementListDetail') || to.path.includes('/monitoringManagementListDetail')) && adminInfo.authBbs < 2)
                  isAuth = false
                else if ((to.path == '/actionManagementList' || to.path == '/monitoringManagementList') && adminInfo.authBbs < 1)
                  isAuth = false

                if (isAuth)
                  next()
                else
                  next('/noAuth')
              }
            },
            (error) => {
              alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
              if (error.status == 403)
                utils.movePage('/login')
            }
          )
        }
      }
      else if(to.path == '/' || to.path == '' || to.path == 'login'){
        if(user.roles === 'ADMIN1')
          next('/clientManagement')
        else if(user.roles === 'ADMIN2')
          next('/groupManagement')
        else if(user.roles === 'ADMIN3')
          next('/safetyCalender')
        else if(user.roles === 'USER')
          next('/login')
      }
      else
        next()
    }
  }
  else {
    next()
  }
})

router.afterEach((to, from) => {
  utils.log('router.afterEach:'+to.path)
  if(!(import.meta.env.MODE == 'development')) {
    const store = useCommonStore()
    const storeRef = storeToRefs(store)
    const user = JSON.parse(localStorage.getItem('user')!)
    //알림갯수 확인
    if (user) {
      AlarmService.getAlarmList({}).then(
        (response) => {
          const alarmList = response.result
          const unReadList = _.filter(alarmList, item => (utils.isEmpty(item.checkIdx)))
          if (unReadList.length > 0)
            storeRef.isAlarm.value = true
          else
            storeRef.isAlarm.value = false
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }
  }
})

export default router

