<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">모듈선택</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>

        <div class="selected-group ty01">
          <v-item-group selected-class="bg-active">
            <v-container>
              <div class="list-group">
                <div class="list-row"
                     v-for="(item,key) in items"
                     :key="key"
                >
                  <div class="list-txt">
                    <v-item v-slot="{ selectedClass, toggle }">
                      <v-card
                        :class="['active', selectedClass]"
                        dark
                        @click="onClickItem(item)"
                      >
                        <!--
                        sort01 안전관찰
                        sort02 안전리더십
                        sort03 시정조치
                        -->
                        <div class="txt" :class="item.type=='b'?'sort01':'sort03'">
                          {{ item.title }}
                        </div>
                      </v-card>
                    </v-item>
                  </div>
                </div>
              </div>
            </v-container>
          </v-item-group>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end"><!--pop-bottom-bg-->
        <c-btn02 btnText="닫기"/>
        <c-btn01 btnText="확인"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref } from 'vue'
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import utils from "@/common/Utils";

const props = defineProps(['popTitle'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const items = ref([])

//hook
onUpdated(() => {
  if(dialog.value)
    items.value = storeRef.popupItems.value
})

//function
const onClickItem = (item:any): void => {
  if(item.type == 'b')
    utils.movePage('/monitoring/1/'+item.idx)
  else if(item.type == 't')
    utils.movePage('/myBoardDetail/'+item.idx)
}
</script>


<style lang="scss">

</style>
