<template>
  <div class="tit-group">
    <h2 class="tit">조직관리</h2>
  </div>
  <div class="tab-group">
    <v-tabs v-model="tab">
      <v-tab value="tabCont1" @click.stop="onClickTab(1)">회사 관리</v-tab>
      <v-tab value="tabCont2" @click.stop="onClickTab(2)">회원 관리</v-tab>
      <v-tab value="tabCont3" @click.stop="onClickTab(3)">관리자 관리</v-tab>
      <v-tab value="tabCont4" @click.stop="onClickTab(4)">시스템 알림</v-tab>
    </v-tabs>
  </div>
  <div class="content-box bg-none">
    <v-tabs-window v-model="tab">
      <v-tabs-window-item value="tabCont1">
        <c-pageTabCont1/>
      </v-tabs-window-item>
      <v-tabs-window-item value="tabCont2">
        <c-pageTabCont2 v-if="pageType=='list'"/>
        <c-pageTabCont21 v-if="pageType=='view'"/>
      </v-tabs-window-item>
      <v-tabs-window-item value="tabCont3">
        <c-pageTabCont3 v-if="pageType=='list'"/>
        <c-pageTabCont31 v-if="pageType=='view'"/>
      </v-tabs-window-item>
      <v-tabs-window-item value="tabCont4">
        <c-pageTabCont4 v-if="pageType=='list'"/>
        <c-pageTabCont41 v-if="pageType=='view'"/>
      </v-tabs-window-item>
    </v-tabs-window>
  </div>
</template>

<style lang="scss">
</style>
<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { useCommonStore } from "@/store/common";
import { storeToRefs } from 'pinia'
import {onMounted} from "vue";
import utils from "@/common/Utils";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//bind
const tab = ref()
const tabIdx = ref(route.params.tab?route.params.tab:'')
const pageType = ref(route.params.page?route.params.page:'')

//hook
onMounted(() => {
  tab.value = 'tabCont'+tabIdx.value
})

function onClickTab(type){
  utils.movePage('/organizationalManagement/'+type+'/'+pageType.value)
}
</script>
