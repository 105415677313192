<template>
  <div class="sort-group ty01">
    <div class="sort-row">
      <div class="wl">
        <div class="date-sort">
          <c-inputSearch v-bind="inputSearch" v-model="searchModel" @search-word="onClickSearch" />
          <c-InputDate v-model="startDate" :enableTimePicker="false" readonly="" />
          <div class="txt02">~</div>
          <c-InputDate v-model="endDate" :enableTimePicker="false" readonly=""/>
        </div>
        <div class="date-sort">
          <c-btn02 :btnText="'일주'" class="ty01" @click="onClickDate('week')"/>
          <c-btn02 :btnText="'한달'" class="ty01" @click="onClickDate('month')"/>
        </div>
      </div>
    </div>
    <div class="sort-row">
      <div class="wl">
        <Select v-bind="deptSelect" v-model="deptModel"/>
        <Select v-bind="posSelect" v-model="posModel"/>
        <Select v-bind="shiftSelect" v-model="shiftModel"/>
        <Select v-bind="monitoringSelect" v-model="monitoringModel"/>
        <Select v-bind="excludeSelect" v-model="excludeModel"/>
      </div>
    </div>
  </div>
  <div class="scrollbar">
    <div class="tbl-group size-custom19">
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        return-object
      >
        <template v-slot:item.situation="{ item }" >
          <div class="tal">
            {{item.monitoringTitle}}{{item.situation}}
          </div>
        </template>
        <template v-slot:item.memberInfo="{ item }" >
          <div class="txt-tag">
            {{item.memberName+'/'+item.deptName+'/'+item.posName}}
          </div>
        </template>
      </v-data-table>
    </div>
    <v-divider class="mb-4"></v-divider>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";
import CompanyService from "@/services/company.service";
import MemberService from "@/services/member.service";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//bind
const snackbar = ref(false)
const snackText = ref('')
let viewIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value
const isExcludeDialogModel = ref(false)
const deptSelect = ref({items: [{name:'부서', value:''}]})
const posSelect = ref({items: [{name:'직책', value:''}]})
const shiftSelect = ref({items: [{name: '교대조', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]})
const monitoringSelect = ref({items: [{name:'모니터링(전체)', value:''}]})
const excludeSelect = ref({items: [{name:'통계', value:''}, {name:'통계제외', value:'Y'},{name:'통계포함', value:'N'}]})
const startDate = ref('')
const endDate = ref('')
const deptModel = ref({name:'부서', value:''})
const posModel = ref({name:'직책', value:''})
const shiftModel = ref({name: '교대조', value: ''})
const monitoringModel = ref({name:'모니터링(전체)', value:''})
const excludeModel = ref({name:'통계', value:''})
const searchModel = ref(null)
const headers = ref([
  { title: '안전대화/칭찬', key: 'situation' },
  { title: '변경자', key: 'memberInfo'},
  { title: '변경내역', key: 'isExclude' },
  { title: '변경일시', key: 'regDate' }
])
const items = ref()
let firstLoadingDone = false

//ui
const inputSearch = {
  type: 'text',
  placeholder: '검색어를 입력하세요. (,로 다중검색)',
}

//watch
watch(startDate, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(endDate, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(deptModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(posModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(shiftModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(monitoringModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

watch(excludeModel, (newObj) => {
  if(firstLoadingDone)
    onClickSearch()
})

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        situation:'안전대화 제목이 ',
        memberInfo: '김동임',
        isExclude: '제외',
        regDateFormat: '2024.03.01 06:32:33',
      },
      {
        situation:'안전대화 제목이 들어갑니다1 안전대화 제목이 들어갑니다1 안전대화 제목이 들어갑니다1',
        memberInfo: '김동임',
        isExclude: '제외',
        regDateFormat: '2024.03.01 06:32:33',
      },
      {
        situation:'안전대화 제목이 들어갑니다1',
        memberInfo: '김동임',
        isExclude: '제외',
        regDateFormat: '2024.03.01 06:32:33',
      },
      {
        situation:'안전대화 제목이 들어갑니다1',
        memberInfo: '김동임',
        isExclude: '제외',
        regDateFormat: '2024.03.01 06:32:33',
      },
    ]
  }
  else {
    if (viewIdx) {
      //본인부서에 해당하는 모니터링, 부서, 맴버정보
      BbsService.getMonitoringFilter({}).then(
        (response) => {
          //모니터링필터
          let monitoringList = response.result.monitoringList
          _.forEach(monitoringList, function (item) {
            monitoringSelect.value.items.push({name: item.monitoringTitle, value: item.idx})
            if (viewIdx == item.idx)
              monitoringModel.value = {name: item.monitoringTitle, value: item.idx}
          })
          //부서필터
          let deptList = response.result.deptList
          _.forEach(deptList, function (item) {
            deptSelect.value.items.push({name: item.treeName, value: item.idx})
          })
          //직책필터
          let posList = response.result.posList
          _.forEach(posList, function (item) {
            posSelect.value.items.push({name: item.treeName, value: item.idx})
          })
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
      firstLoadingDone = true
    }
  }
})

function onClickSearch(){
  const user = JSON.parse(localStorage.getItem('user'))
  let params = {
    keyword: searchModel.value,
    startDate:startDate.value,
    endDate:endDate.value,
    companyIdx:user.companyIdx,
    isExclude:excludeModel.value['value'],
    monitoringIdx:monitoringModel.value['value'],
    deptIdx:deptModel.value['value'],
    posIdx:posModel.value['value'],
    shiftYn:shiftModel.value['value'],
    excludeType:'1'  //타입 1:모니터링 2:안전대화
  }

  BbsService.getAnswerExcludeLogList(params).then(
    (response) => {
      items.value = response.result
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickDate(type){
  const end = dayjs().format('YYYY-MM-DD')
  let start = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  if(type == 'month')
    start = dayjs().subtract(1, 'month').format('YYYY-MM-DD')

  startDate.value = start.toString()
  endDate.value = end.toString()
}
</script>


<style lang="scss">
</style>
