<template>
  <div class="content-box bg-none">
    <div class="top-cont">
      <div class="switch-group">
        <div class="col">
          <div class="tit">안전관찰</div>
          <v-switch v-model="bbsModel" inset hide-details @change="changeSwitch"/>
        </div>
        <div class="col ty01" v-if="false">
          <div class="tit">안전리더십</div>
          <v-switch v-model="leadershipModel" inset hide-details @change="changeSwitch"/>
        </div>
        <div class="col ty02">
          <div class="tit">시정조치</div>
          <v-switch v-model="timelineModel" inset hide-details @change="changeSwitch"/>
        </div>
      </div>
    </div>
    <div id="example-full" class="safety-calendar">
      <div class="calendar-parent">
        <CalendarView
          :items="state.items"
          :show-date="state.showDate"
          :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
          :enable-drag-drop="false"
          :disable-past="state.disablePast"
          :disable-future="state.disableFuture"
          :show-times="state.showTimes"
          :date-classes="myDateClasses()"
          :display-period-uom="state.displayPeriodUom"
          :display-period-count="state.displayPeriodCount"
          :starting-day-of-week="state.startingDayOfWeek"
          :class="themeClasses"
          :current-period-label="state.useTodayIcons ? 'icons' : ''"
          :displayWeekNumbers="state.displayWeekNumbers"
          :enable-date-selection="true"
          :selection-start="state.selectionStart"
          :selection-end="state.selectionEnd"
          @click-date="onClickDay"
          @click-item="onClickItem"
        >
          <template #header="{ headerProps }">
            <CalendarViewHeader :header-props @input="setShowDate" />
          </template>
        </CalendarView>
      </div>
    </div>
  </div>
  <DialogModule v-model="DialogModuleModel"
                @close="DialogModuleModel=false"
  />
</template>

<script setup lang="ts">
import {onMounted, computed, ref} from 'vue';
import CalendarService from "@/services/calendar.service";
import CalendarView from "../components/scalendar/CalendarView.vue"
import CalendarViewHeader from "../components/scalendar/CalendarViewHeader.vue"
import CalendarMath from "../components/scalendar/CalendarMath"
import { ICalendarItem, INormalizedCalendarItem } from "../components/scalendar/ICalendarItem"
import utils from "@/common/Utils";
import _ from 'lodash';
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const DialogModuleModel = ref(false)
const bbsModel = ref(true)
const leadershipModel = ref(true)
const timelineModel = ref(true)
let allList = []
let clickedList = []

//hook
onMounted((): void => {
  state.value.newItemStartDate = CalendarMath.isoYearMonthDay(CalendarMath.today())
  state.value.newItemEndDate = CalendarMath.isoYearMonthDay(CalendarMath.today())

  if(import.meta.env.MODE == 'development'){
    state.value.items = [
      {
        id: "e1",
        startDate: thisMonth(3),
        classes: ["sort01"],
        title: "평택지사 안전관찰 1회차 안전관찰안전관찰",
        tooltip: "평택지사 안전관찰 1회차 안전관찰안전관찰",
      },
      {
        id: "e2",
        startDate: thisMonth(3),
        classes: ["sort02"],
        title: "안전리더십 1회차",
        tooltip: "안전리더십 1회차",
      },
      {
        id: "e3",
        startDate: thisMonth(3),
        classes: ["sort03"],
        title: "시정조치 A업무",
        tooltip: "시정조치 A업무",
      },
      {
        id: "e4",
        startDate: thisMonth(5),
        classes: ["sort01"],
        title: "평택지사 안전관찰 1회차 안전관찰안전관찰",
        tooltip: "평택지사 안전관찰 1회차 안전관찰안전관찰",
      },
      {
        id: "e5",
        startDate: thisMonth(5),
        classes: ["sort03"],
        title: "시정조치 A업무",
        tooltip: "시정조치 A업무",
      },
      {
        id: "e5",
        startDate: thisMonth(5),
        classes: ["sort04"],
        title: "3 more",
      },
      {
        id: "e6",
        startDate: thisMonth(18),
        classes: ["sort01"],
        title: "평택지사 안전관찰 1회차 안전관찰안전관찰",
        tooltip: "평택지사 안전관찰 1회차 안전관찰안전관찰",
      },
      {
        id: "e7",
        startDate: thisMonth(18),
        classes: ["sort03"],
        title: "시정조치 A업무",
        tooltip: "시정조치 A업무",
      },
    ]
  }
  else {
    CalendarService.getCalendarList({calendarType: 'm'}).then(
      (response) => {
        allList = []
        _.forEach(response.result.bbsList, function (item) {
          allList.push({idx:item.idx, id: 'bbs' + item.idx, startDate: toDate(item.startDate), formatDate:toDate(item.startDate).toISOString().split('T')[0], classes: ["sort01"], title: item.monitoringTitle+'('+item.periodSeq+')', tooltip: item.monitoringTitle, type:'b'})
        })
        _.forEach(response.result.timelineList, function (item) {
          allList.push({idx:item.idx, id: 'timeline' + item.idx, startDate: toDate(item.goalDate), formatDate:toDate(item.goalDate).toISOString().split('T')[0], classes: ["sort03"], title: item.status, tooltip: item.status, type:'t'})
        })
        //3개이상은 more처리
        let showList = []
        let preDate = ''
        let dateCnt = 0
        _.forEach(allList, function (item) {
          if(preDate != item.formatDate) {
            showList.push({idx: item.idx, id: item.idx, startDate: item.startDate, formatDate: item.formatDate, classes: item.classes, title: item.title, tooltip: item.tooltip, type: item.type})
            dateCnt = 0
          }
          else if(preDate == item.formatDate){
            if(dateCnt < 2)
              showList.push({idx: item.idx, id: item.idx, startDate: item.startDate, formatDate: item.formatDate, classes: item.classes, title: item.title, tooltip: item.tooltip, type: item.type})
            else if(dateCnt == 2)
              showList.push({idx: item.idx, id: item.idx, startDate: item.startDate, formatDate: item.formatDate, classes: ['sort04'], title: 'more', tooltip: 'more', type: item.type})
          }
          dateCnt++
          preDate = item.formatDate
        })
        state.value.items = showList
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
})

//function
const changeSwitch = ()=>{
  let isBbs = bbsModel.value
  let isTimeline = timelineModel.value
  let newList = []
  if(isBbs && !isTimeline)
    newList = _.filter(allList, item => item.type === 'b')
  else if(!isBbs && isTimeline)
    newList = _.filter(allList, item => item.type === 't')
  else if(isBbs && isTimeline)
    newList = _.filter(allList, item => item.type === 'b' || item.type === 't')
  state.value.items = newList
}

//calendar
const thisMonth = (d: number, h?: number, m?: number): Date => {
  const t = new Date()
  return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
}

const toDate = (date:string): Date=>{
  const t = new Date(date)
  return new Date(t.getFullYear(), t.getMonth(), t.getDate())
}

interface IExampleState {
  showDate: Date
  message: string
  startingDayOfWeek: number
  disablePast: boolean
  disableFuture: boolean
  displayPeriodUom: string
  displayPeriodCount: number
  displayWeekNumbers: boolean
  showTimes: boolean
  selectionStart?: Date
  selectionEnd?: Date
  newItemTitle: string
  newItemStartDate: string
  newItemEndDate: string
  useDefaultTheme: boolean
  useHolidayTheme: boolean
  useTodayIcons: boolean
  items: ICalendarItem[]
}
const state = ref({
  /* Show the current month, and give it some fake items to show */
  showDate: thisMonth(1),
  message: "",
  startingDayOfWeek: 0,
  disablePast: false,
  disableFuture: false,
  displayPeriodUom: "month",
  displayPeriodCount: 1,
  displayWeekNumbers: false,
  showTimes: true,
  selectionStart: undefined,
  selectionEnd: undefined,
  newItemTitle: "",
  newItemStartDate: "",
  newItemEndDate: "",
  useDefaultTheme: true,
  useHolidayTheme: true,
  useTodayIcons: true,
  enableDragDrop: false,
  items: [],
} as IExampleState)
const userLocale = computed((): string => CalendarMath.getDefaultBrowserLocale())
const dayNames = computed((): string[] => CalendarMath.getFormattedWeekdayNames(userLocale.value, "long", 0))
const themeClasses = computed(() => ({
  "theme-default": state.value.useDefaultTheme,
  "holiday-us-traditional": state.value.useHolidayTheme,
  "holiday-us-official": state.value.useHolidayTheme,
}))
const myDateClasses = (): Record<string, string[]> => {
  const o = {} as Record<string, string[]>
  const theFirst = thisMonth(1)
  const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13
  const idesDate = thisMonth(ides)
  o[CalendarMath.isoYearMonthDay(idesDate)] = ["ides"]
  o[CalendarMath.isoYearMonthDay(thisMonth(21))] = ["do-you-remember", "the-21st"]
  return o
}
const onClickDay = (d: Date): void => {
  let clickedDate = d.toISOString().split('T')[0]
  clickedList = _.filter(allList, item => item.formatDate == clickedDate)
  if(clickedList.length > 0){
    storeRef.popupItems.value = clickedList
    DialogModuleModel.value = true
  }
}
const onClickItem = (item: INormalizedCalendarItem): void => {
  let clickedDate = item.originalItem.formatDate
  clickedList = _.filter(allList, item => item.formatDate == clickedDate)
  if(clickedList.length > 0){
    storeRef.popupItems.value = clickedList
    DialogModuleModel.value = true
  }
}
const setShowDate = (d: Date): void => {
  state.value.showDate = d
}

</script>


<style lang="scss">
/*
@import "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.min.css";
*/
@import "../assets/css/scalendar/gcal.css";
/*휴일 표시 삭제*/
/*
@import "../assets/css/scalendar/holidays-us.css";
@import "../assets/css/scalendar/holidays-ue.css";
 */
#example-full {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80rem;
  margin-top:0.9rem;
  margin-left: auto;
  margin-right: auto;
}

#example-full .calendar-controls {
  margin-right: 1rem;
  min-width: 14rem;
  max-width: 14rem;
}

#example-full .calendar-parent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*overflow-x: hidden;*/
  /*overflow-y: hidden;*/
}

/* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
#example-full .cv-wrapper.period-month.periodCount-2 .cv-week,
#example-full .cv-wrapper.period-month.periodCount-3 .cv-week,
#example-full .cv-wrapper.period-year .cv-week {
  min-height: 6rem;
}
/*
#example-full .theme-default .cv-day.ides{
  background-color: #ffe0e0;
}
*/
</style>
