<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="96rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">데이터 유효성 검사
            <div class="sub-txt">
              특정 기록을 보고서 집계에서 제외할 수 있으며, 언제든지 원복할 수 있습니다.
            </div>
          </div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>
        <!--content-->
        <div class="pop-cont05">
          <div class="tab-group ty01 ty01-01">
            <v-tabs
              v-model="tab"
            >
              <v-tab value="tabCont1">모니터링</v-tab>
              <v-tab value="tabCont2">안전대화</v-tab>
              <v-tab value="tabCont3">변경내역</v-tab>
            </v-tabs>
          </div>
        </div>
        <v-card-text>
          <v-tabs-window v-model="tab">
            <v-tabs-window-item value="tabCont1">
              <MtDialog03Cont1/>
            </v-tabs-window-item>
            <v-tabs-window-item value="tabCont2">
              <MtDialog03Cont2/>
            </v-tabs-window-item>
            <v-tabs-window-item value="tabCont3">
              <MtDialog03Cont3/>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 :btnText="'확인'" @click="onClickConfirm"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import {ref} from 'vue';

const emit = defineEmits(['close', 'confirm'])
const dialog = defineModel()

//bind
const tab = ref('tabCont1')

function onClickConfirm(){
  emit('confirm')
  dialog.value = false
}
</script>


<style lang="scss">
</style>
