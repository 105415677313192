<template>
  <div class="tr-page-wrap">
    <div class="tit-group">
      <h2 class="tit">통합보고서</h2>
    </div>
    <div class="page3">
      <div class="tab-group">
        <v-tabs
          v-model="tab"
        >
          <v-tab value="tabCont1">안전관리</v-tab>
          <v-tab value="tabCont2" v-if="false">안전리더십</v-tab>
        </v-tabs>
      </div>
      <div class="content-box bg-none tab-cont-wrap">
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tabCont1">
            <totalReportTabCont1/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont2">
            <totalReportTabCont2/>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useCommonStore } from "@/store/common";
import { storeToRefs } from 'pinia'
const route = useRoute()
const store = useCommonStore()
const storeRef = storeToRefs(store)
store.moveTab('tabCont1')
const tab = storeRef.tabName
</script>


<style lang="scss">

</style>
