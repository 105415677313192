<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="tit-group ty01">
        <h4 class="tit">{{monitoringInfo.monitoringTitle}}</h4>
        <div class="alert-group" v-if="auditTargetCnt > 0">
          <v-alert text="AUDIT 활동 중입니다"></v-alert>
        </div>
      </div>
      <div class="tab-group ty01">
        <v-tabs
          v-model="tab"
        >
          <v-tab value="tabCont1">행동관찰</v-tab>
          <v-tab value="tabCont2">안전대화</v-tab>
        </v-tabs>
      </div>
      <div class="content-box bg-none">
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tabCont1">
            <div class="form-group col-ty">
              <!--form-row-->
              <div class="form-row ty03">
                <div class="txt-tit ty02">
                  <c-tooltip :TooltipText="'관리자가 관리하는 항목입니다. 추가/변경 시 사내 관리자에게 문의 주시기 바랍니다.'"/>
                  장소
                </div>
                <div class="row">
                  <Select v-bind="placeTab1Select" v-model="place"/>
                </div>
              </div>
            </div>
            <div class="list-group2 ty01">
              <!--list-->
              <div class="list" v-for="(item, i) in monitoringItems" :key="i">
                <div class="row1">
                  <div class="num">{{i+1}}</div>
                  <div class="txt">{{item.behaviorTitle}}</div>
                  <div class="right-area">
                    <div class="check-group ty01">
                      <v-checkbox label="해당없음"
                                  :model-value="item.etcYn"
                                  @click.stop="item.etcYn=='Y'?'N':'Y'"
                                  true-value="Y" false-value="N"></v-checkbox>
                    </div>
                    <c-btnText01 :btnText="'안전대화로 이동'" class="ty03" @click.prevent="onClickTimeline(item)"/>
                  </div>
                </div>
                <div class="row2">
                  <div class="radio-group2">
                    <v-radio-group v-model="item.answerData" @change.stop.prevent="onClickReg('Y')">
                      <v-radio label="안전" value="1"></v-radio>
                      <v-radio label="불안전" value="0"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="inner" v-if="item.answerData == 0">
                    <div class="radio-group3">
                      <v-radio-group v-model="item.comment">
                        <v-radio v-for="(item2, i2 ) in item.behaviorComments"
                                 :key="i2"
                                 :label="item2.commentTitle"
                                 :value="item2.commentTitle+','+item2.idx"></v-radio>
                      </v-radio-group>
                    </div>
                    <c-input :placeholder="''" v-model="item.commentEtc" @focusin="onInputEtc($event, i)"></c-input>
                  </div>
                </div>
              </div>
              <!--list-->
            </div>
            <div class="bottom-btn-group ty02">
              <div class="wl">
              </div>
              <div class="wr">
                <c-btn01 :btnText="'제출'" @click.prevent.stop="onClickReg('N')" />
              </div>
            </div>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont2">
            <div class="dropdown-group2">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel v-for="(item, i) in timelineItems" :key="i">
                  <v-expansion-panel-title>안전대화{{i+1}}</v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <div class="form-group col-ty">
                      <div class="tit-group">
                        <div class="tit02">기본정보</div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">타입</div>
                        <div class="row">
                          <div class="radio-group">
                            <v-radio-group v-model="item.type" @click="onClickTimelineReg('Y', i+1)">
                              <v-radio value="1" color="indigo" label="교정적 안전대화" class="r-size01"></v-radio>
                              <v-radio value="2" color="indigo" label="지지적 안전대화"></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row" v-if="item.type=='1'">
                        <div class="txt-tit">
                          시정조치여부
                          <c-tooltip :TooltipText="'실제 시정 작업 할당 여부를 선택하는 기능입니다'"/>
                        </div>
                        <div class="row">
                          <div class="radio-group">
                            <v-radio-group v-model="item.actionYn" @click="onClickTimelineReg('Y', i+1)">
                              <v-radio value="N" color="indigo" label="불필요" class="r-size01"></v-radio>
                              <v-radio value="Y" color="indigo" label="필요"></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row" v-if="item.actionYn=='Y' || item.type=='2'">
                        <div class="txt-tit ty03">
                          대상자
                        </div>
                        <div class="row">
                          <div class="radio-group">
                            <v-radio-group v-model="item.targetType" @click="onClickTimelineReg('Y', i+1)">
                              <v-radio value="1" color="indigo" label="별도지정 없음" class="r-size01"></v-radio>
                              <v-radio value="2" color="indigo" label="부서" @click="onChangeTarget(item, 'dept')"></v-radio>
                              <v-radio value="3" color="indigo" label="구성원" @click="onChangeTarget(item, 'member')"></v-radio>
                            </v-radio-group>
                          </div>
                          <p class="txt04" v-if="item.targetType == '1'">대상없음</p>
                          <p class="txt04" v-if="item.targetType == '2'">{{ item.targetDeptName }}</p>
                          <p class="txt04" v-if="item.targetType == '3'">{{ item.targetMemberName }}</p>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row" v-if="item.actionYn=='Y'">
                        <div class="txt-tit">
                          조치기한
                        </div>
                        <div class="row">
                          <c-InputDate v-model="item.goalDate" :enableTimePicker="false" class="size02" readonly=""/>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-ty line-ty">
                      <div class="tit-group">
                        <div class="tit02">상세정보</div>
                      </div>
                      <!--form-row-->
                      <div class="form-row" v-if="item.type=='1'">
                        <div class="txt-tit">모니터링행동</div>
                        <div class="row">
                          <Select v-bind="behaviorSelect" v-model="item.behaviorIdx" @change="onClickTimelineReg('Y', i+1)"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row" v-if="item.type=='1'">
                        <div class="txt-tit">장소</div>
                        <div class="row">
                          <Select v-bind="placeTab1Select" v-model="item.place" @change="onClickTimelineReg('Y', i+1)"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">{{item.type=='1'?'구체적상황':'칭찬 사유'}}</div>
                        <div class="row">
                          <c-Input :placeholder="item.type=='1'?'상황을 누구나 이해할 수 있도록 구체적으로 작성하여 주세요.':'칭찬 사유를 구체적으로 입력 해주세요.'" v-model="item.situation" @focusout="onClickTimelineReg('Y', i+1)"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row" v-if="item.type=='1'">
                        <div class="txt-tit">원인</div>
                        <div class="row">
                          <c-Input :placeholder="'개인적 판단이 아닌, 대화 과정에서 파악한 원인을 작성 해주세요.'" v-model="item.cause" @focusout="onClickTimelineReg('Y', i+1)"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row" v-if="item.type=='1'">
                        <div class="txt-tit">시정조치내용</div>
                        <div class="row">
                          <c-Input :placeholder="'구체적인 시정 조치 내용을 입력 해주세요.'" v-model="item.action" @focusout="onClickTimelineReg('Y', i+1)"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit ty03">이미지<br>(최대 5장)</div>
                        <div class="row">
                          <InputImgAdd :seq="i+1"/>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div class="bottom-btn-group ty02">
              <div class="wl">
              </div>
              <div class="wr">
                <c-btnLine01 :btnText="'안전대화 추가'" @click="dialogCopyModel=true"/>
                <c-btn01 :btnText="'제출'" @click.prevent.stop="onClickReg('N')"/>
              </div>
            </div>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
    <!--
    <div class="bottom-btn-group">
      <div class="wl">
      </div>
      <div class="wr">
        <c-btnLine01 :btnText="'안전대화 추가'"/>
        <c-btn01 :btnText="'제출'"/>
      </div>
    </div>
    -->
  </div>
  <DialogDept
    v-model="deptDialogModel"
    :popTitle="'부서'"
    @close="deptDialogModel=false"
    @confirm="confirmDeptDialog"/>
  <DialogTargetAdd
    v-model="memberDialogModel"
    @close="memberDialogModel=false"
    @confirm="confirmMemberDialog"/>
  <!--popup: 임시저장 불러오기-->
  <c-dialog v-model="dialogTempModel"
            v-bind="dialogTempBind"
            @close="dialogTempModel=false"
            @confirm="confirmTemp"
  />
  <c-dialog v-model="dialogCopyModel"
            v-bind="dialogCopyBind"
            @close="dialogCopyModel=false"
            @cancel="confirmCopy('cancel')"
            @confirm="confirmCopy('confirm')"
  />
  <v-snackbar v-model="snackbar" :timeout="3000"> {{ snackText }} </v-snackbar>
</template>

<script setup lang="ts">
import {ref, computed, watch, onMounted} from 'vue';
import {useCommonStore} from "@/store/common";
import BbsService from "@/services/bbs.service";
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//bind
const snackbar = ref(false)
const snackText = ref('')
let viewIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value
const deptDialogModel = ref(false)
const memberDialogModel = ref(false)
const tab = ref('tabCont1')
const monitoringItems:any = ref([])
const place = ref({name:'미선택', value:''})
const auditTargetCnt = ref(0)
const monitoringInfo:any = ref({})
const dialogTempModel = ref(false)
const timelineItems:any = ref([])
const panel = ref([0])
const dialogCopyModel = ref(false)
const selectedItem:any = ref(null)
const selectedBehavior:any = ref(null)

//ui
const placeTab1Select = ref({items: [{name: '미선택', value: ''}]})
const behaviorSelect = ref({items: [{name:'동행 점검 시 동일한 포인트를 확인하여 위험예지', value: ''}, {name:'일일미팅(모닝미팅/TBM)을 통해 특별히 안전에 신경써야 할 사항을 공유하고 주시시킨다', value:''}]})
//const placeTab2Select = ref({items: [{name: '미선택', value: ''}]})
let dialogTempBind = {
  dialogTit: '임시 저장  불러오기',
  dialogText: '',
  btn1Text: '불러오기',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const dialogCopyBind = {
  dialogTit: '불러오기',
  dialogText: '직전에 작성된 안전대화의 기본 정보를 불러올까요?',
  btn1Text: '불러오기',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}

// watch
watch(tab, async (newTab, oldTab) => {
  //선택된 행동 초기화
  if(newTab == 'tabCont1'){
    selectedBehavior.value = null
  }
  else if(newTab == 'tabCont2'){
    //안전대화 없으면 템플릿 추가
    if(timelineItems.value.length == 0 || selectedBehavior.value) {
      timelineItems.value.push({
        answerIdx: '',
        type: '1',
        actionYn: 'N',
        targetType: '1',
        behaviorIdx: selectedBehavior.value?{name: selectedBehavior.value.behaviorTitle, value:selectedBehavior.value.idx}:{name: '미선택', value:''},
        place: {name: '미선택'},
      })
    }
  }
})

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    timelineItems.value=[{
      answerIdx: '',
      type: '1',
      actionYn: 'N',
      targetType: '1',
      behaviorIdx:{name:'미선택'},
      place:{name:'미선택'}
    }]
    monitoringItems.value = [
      {
        "idx": 1,
        "behaviorTitle": "1",
        "behaviorContent": "2",
        "behaviorType": "2",
        "itemCount": 0,
        "multiYn": "N",
        "tag": "#tag,#tag2",
        "delYn": "N",
        "essentialYn": "Y",
        "regDate": "2024-12-01 20:29:52.156732",
        'answerData': '1',
        "duplicateYn": 'Y',
        "behaviorComments": [
          {
            "idx": 3,
            "behaviorIdx": 2,
            "commentTitle": "22",
            "showOrder": 1,
            "regIdx": 0,
            "regDate": "2024-12-01 21:04:48.446044"
          },
          {
            "idx": 6,
            "behaviorIdx": 2,
            "commentTitle": "22",
            "showOrder": 1,
            "regIdx": 1,
            "regDate": "2024-12-02 01:26:31.26957"
          }
        ]
      },
      {
        "idx": 2,
        "behaviorTitle": "2",
        "behaviorContent": "22",
        "behaviorType": "2",
        "itemCount": 0,
        "multiYn": "N",
        "tag": "333",
        "delYn": "N",
        "essentialYn": "Y",
        "regDate": "2024-12-01 21:04:48.400642",
        'answerData': '1',
        "duplicateYn": 'Y'
      }
    ]
    monitoringInfo.value = {
      "idx": 6,
      "companyIdx": 3,
      "monitoringTitle": "test2",
      "monitoringDiv": null,
      "startDate": "2024-12-12 00:00:00.0",
      "endDate": "2025-01-31 00:00:00.0",
      "periodType": "2",
      "periodStartDay": "1",
      "periodCnt": 2,
      "duplicateYn": "Y",
      "reportDay": "1",
      "reportAutoYn": "Y",
      "showOrder": 0,
      "showYn": "N",
      "activeYn": "Y",
      "delYn": "N",
      "modIdx": 34,
      "regIdx": 0,
      "modDate": "2024-12-25 00:49:25.046232",
      "regDate": "2024-12-08 22:44:31.078994",
      "targetCnt": 1,
      "auditTargetCnt": 0,
    }
  }
  else {
    if(viewIdx) {
      const user = JSON.parse(localStorage.getItem('user'))
      let params = {
        idx: viewIdx,
        memberIdx: user.memberIdx,
        companyIdx: user.companyIdx,
        isTemp: 'Y'
      }

      BbsService.getBbsMonitoring(params).then(
        (response) => {
          //모니터링
          monitoringInfo.value = response.result

          //행동
          let monitoringItemArr = []
          let behaviorArr = {items:[{name:'미선택', value:''}]}
          _.forEach(response.result.monitoringBehaviors, function(item, key) {
            item['answerData'] = '1'
            monitoringItemArr.push(item)
            let tempBehavior = {name:item.behaviorTitle, value:item.idx}
            behaviorArr.items.push(tempBehavior)
          })
          monitoringItems.value = monitoringItemArr
          behaviorSelect.value = behaviorArr

          //audit타겟수
          auditTargetCnt.value = response.result.auditTargetCnt

          //장소
          let placeArr = {items:[{name:'미선택', value:''}]}
          _.forEach(response.result.monitoringPlaces, function(val, key) {
            let tempObj = {name:val['place'], value:val['idx']}
            placeArr.items.push(tempObj)
          })
          placeTab1Select.value = placeArr

          //임시저장
          _.forEach(response.result.monitoringAnswers, function (val, key){
            if(val.isTemp == 'Y'){
              dialogTempBind.dialogText = '최종 제출되지 않고, 임시 저장된 모니터링이 존재합니다. <br>불러올까요? <br>'+val.regDate
              dialogTempModel.value = true
            }
          })

          //타임라인
          if(response.result.monitoringTimelines.length > 0){
            timelineItems.value = response.result.monitoringTimelines
            _.forEach(timelineItems.value, function(val, key) {
              //val.deptName =
              val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? null:dayjs(val.goalDate).format('YYYY-MM-DD')
            })
          }
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }
  }
})

//function
function onClickTimeline(idx){
  selectedBehavior.value = idx
  tab.value='tabCont2'
}

function onChangeTarget(item, type){
  if(type=='dept'){
    let companyIdx = localStorage.getItem('companyIdx')
    storeRef.popupIdx.value = companyIdx
    storeRef.popupType.value = type
    selectedItem.value = item
    deptDialogModel.value=true
  }
  else if(type=='member'){
    selectedItem.value = item
    memberDialogModel.value=true
  }
}

function confirmDeptDialog(params){
  deptDialogModel.value=false
  if(params !== false) {
    let resultName = []
    let resultIdxs = []
    _.forEach(params, function(obj, key) {
      resultName.push(obj.treeName)
      resultIdxs.push(obj.idx)
    });
    selectedItem.value.targetDeptName = resultName[0]
    selectedItem.value.targetDeptIdx = resultIdxs[0]
  }
}

function confirmMemberDialog(params) {
  memberDialogModel.value=false
  if(!utils.isEmpty(params)){
    selectedItem.value.targetMemberName = params.memberInfo
    selectedItem.value.targetMemberIdx = params.idx
  }
}

function confirmTemp(){
  _.forEach(monitoringItems.value, function(val, key) {
    _.forEach(monitoringInfo.value.monitoringAnswers, function(val2, key2) {
      if(val2.isTemp == 'Y') {
        place.value = _.find(placeTab1Select.value.items, {value: val2.placeIdx})
        _.forEach(val2.answers, function (val3, key3) {
          if (val3.behaviorIdx == val.idx) {
            val.answerData = val3.answerData
          }
        })
      }
    })
  })
}

function onClickReg(isTemp){
  if(import.meta.env.MODE == 'development')
    return

  if(isTemp == 'N' && monitoringInfo.value.timelineYn == 'Y'){
    if(timelineItems.value.length <= 0) {
      snackText.value = '안전대화 필수입니다'
      snackbar.value = true
      tab.value = 'tabCont2'
      return
    }
  }

  let isDuplicate = false
  if(monitoringInfo.value.duplicateYn != 'Y'){
    _.forEach(monitoringInfo.value.monitoringAnswers, function(val, key) {
      if(val.isTemp == 'N'){
        if(isTemp == 'N') {
          snackText.value = '완료된 행동관찰입니다'
          snackbar.value = true
          isDuplicate = true
        }
      }
    })
  }
  if(isDuplicate)
    return

  if(viewIdx) {
    let answers = []
    _.forEach(monitoringItems.value, function (item, key) {
      let tempObj = {}
      tempObj['behaviorIdx'] = item.idx
      tempObj['behaviorType'] = item.behaviorType
      tempObj['answerData'] = item.answerData
      tempObj['etcYn'] = item.etcYn
      if(!utils.isEmpty(item.commentEtc)){
        tempObj['comment'] = item.commentEtc
        tempObj['commentIdx'] = 0
      } else {
        if(item.comment) {
          tempObj['comment'] = item.comment.split(',')[0]
          tempObj['commentIdx'] = item.comment.split(',')[1]
        }
      }
      tempObj['isTemp'] = isTemp
      answers.push(tempObj)
    })

    const user = JSON.parse(localStorage.getItem('user'))
    let params = {
      monitoringIdx: viewIdx,
      periodSeq: monitoringInfo.value.periodSeq,
      memberIdx: user.memberIdx,
      companyIdx: user.companyIdx,
      deptIdx: user.deptIdx,
      posIdx: user.posIdx,
      isTemp: isTemp,
      placeIdx: place.value['value'],
      answers: answers,
    }

    BbsService.insertAnswer(params).then(
      (response) => {
        if (isTemp == 'N') {
          if (response.success) {
            snackText.value = 'success'
            snackbar.value = true
            setTimeout(() => utils.movePage('/actionManagement'), 2000);
          } else
            alert(response.message)
        }
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function onClickTimelineReg(isTemp, tempSeq) {
  const user = JSON.parse(localStorage.getItem('user'))
  let curItem = _.nth(timelineItems.value, tempSeq-1)
  let params = {
    monitoringIdx: viewIdx,
    memberIdx: user.memberIdx,
    companyIdx: user.companyIdx,
    type:curItem.type,
    actionYn: curItem.actionYn,
    goalDate: curItem.goalDate,
    targetType: curItem.targetType,
    targetDeptIdx: curItem.targetDeptIdx,
    targetMemberIdx: curItem.targetMemberIdx,
    behaviorIdx: curItem.behaviorIdx ? curItem.behaviorIdx['value'] : '',
    place: curItem.place ? curItem.place['value'] : '',
    situation: curItem.situation,
    cause: curItem.cause,
    action: curItem.action,
    isTemp: isTemp,
    tempSeq: tempSeq,
  }

  TimelineService.insertTimeline(params).then(
    (response) => {
      utils.log(response.result)
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function confirmCopy(type){
  dialogCopyModel.value=false
  let lastObj = _.last(timelineItems.value)
  let tempObj = {}
  if(type == 'confirm') {
    tempObj['companyIdx'] = lastObj.companyIdx
    tempObj['monitoringIdx'] = lastObj.monitoringIdx
    tempObj['answerIdx'] = lastObj.answerIdx
    tempObj['memberIdx'] = lastObj.memberIdx
    tempObj['type'] = lastObj.type
    tempObj['actionYn'] = lastObj.actionYn
    tempObj['targetType'] = lastObj.targetType
    tempObj['targetDeptIdx'] = lastObj.targetDeptIdx
    tempObj['targetMemberIdx'] = lastObj.targetMemberIdx
    tempObj['targetDeptName'] = lastObj.targetDeptName
    tempObj['targetMemberName'] = lastObj.targetMemberName
    tempObj['goalDate'] = lastObj.goalDate
    tempObj['behaviorIdx'] = lastObj.behaviorIdx
    tempObj['place'] = lastObj.place
    tempObj['situation'] = lastObj.situation
    tempObj['cause'] = lastObj.cause
    tempObj['action'] = lastObj.action
    tempObj['delYn'] = lastObj.delYn
    tempObj['isTemp'] = lastObj.isTemp
  }
  else {
    tempObj['companyIdx'] = ''
    tempObj['monitoringIdx'] = ''
    tempObj['answerIdx'] = ''
    tempObj['memberIdx'] = ''
    tempObj['type'] = ''
    tempObj['actionYn'] = ''
    tempObj['targetType'] = ''
    tempObj['targetDeptIdx'] = ''
    tempObj['targetMemberIdx'] = ''
    tempObj['targetDeptName'] = ''
    tempObj['targetMemberName'] = ''
    tempObj['goalDate'] = ''
    tempObj['behaviorIdx'] = ''
    tempObj['place'] = ''
    tempObj['situation'] = ''
    tempObj['cause'] = ''
    tempObj['action'] = ''
    tempObj['delYn'] = ''
    tempObj['isTemp'] = ''
  }
  timelineItems.value.push(tempObj)
}

function onInputEtc(value, index){
  if(value) {
    monitoringItems.value[index].comment = null
  }
}
</script>


<style lang="scss" >

</style>
