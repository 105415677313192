<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt-total">Total: {{ totalCnt }}</div>
            <c-InputSearch :placeholder="'검색어를 입력하세요. (,로 다중검색)'"  v-model="inputSearch" @search-word="onClickSearch"/>
          </div>
          <div class="wr">
            <!-- c-btnMenu01 v-bind="btnExcelUpload" @click.stop="excelUpload()"/ -->
            <c-btn01 v-bind="btnEachUpload" @click="onClickViewPage3"/>
          </div>
        </div>
      </div>
      <!-- v-data-table 가져옴-->
      <div class="tbl-group size-custom2">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          item-value="id"
          :items-per-page-options="footerOptions"
          return-object
          show-select
          @click:row="onClickViewPage3"
        >
          <template v-slot:item.regDate="{ item }" >
            <div class="ell01">
              {{ dayjs(item.regDate).format('YYYY-MM-DD') == 'Invalid Date' ? '' : dayjs(item.regDate).format('YYYY-MM-DD HH:mm:ss') }}
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="bottom-btn-group">
        <div class="wl"></div>
        <div class="wr">
          <c-btnLine01 v-bind="btnListDel" />
        </div>
      </div>
    </div>
  </div>
  <v-snackbar v-model="snackbar" :timeout="3000"> {{ snackText }} </v-snackbar>
</template>
<style lang="scss" >
</style>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useCommonStore } from "@/store/common";
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";
import dayjs from "dayjs";
import {useRoute} from "vue-router";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//bind
const snackbar = ref(false)
const snackText = ref('')
const totalCnt = ref(0)
const inputSearch = ref(null)
const selected = ref([])
const headers = ref([
  { title: '순번', key: 'idx' },
  { title: '아이디', key: 'memberId' },
  { title: '이름', key: 'memberName'},
  { title: '부서 ', key: 'deptName' },
  { title: '직책 ', key: 'posName'},
  { title: '교대조 ', key: 'shiftYn' },
  { title: '등록일 ', key: 'regDate' },
  { title: '사용여부', key: 'useYn' },
])
const items = ref([])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]

//ui
const btnExcelUpload = {
  btnText: '엑셀 등록',
  setItems: [
    { title: '샘플' },
    { title: '업로드' }
  ],
}

const btnEachUpload = {
  btnText: '개별 등록',
}
const btnListDel = {
  btnText: '선택삭제',
}

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        num: 1,
        id: 'test1',
        name: '김동기',
        groupNum: 5,
        userNum: 500,
        rank: '부장',
        shiftWork: '교대조A',
        dateRegister: '2024-10-10',
        use: 'Y',
      },
      {
        num: 2,
        id: 'test아이디1234',
        name: '이름abcdefg',
        groupNum: 5,
        userNum: 500,
        rank: '직급1234',
        shiftWork: '교대조A',
        dateRegister: '2024-10-10',
        use: 'Y',
      }
    ]
    totalCnt.value = 2
  }
  else{
    onClickSearch('')
  }
})

//function
function onClickSearch(word){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    keyword: word,
    companyIdx:companyIdx
  }

  MemberService.getCompanyAdminList(params).then(
    (response) => {
      items.value = response.result
      totalCnt.value = response.result.length
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickViewPage3(event, row){
  if(row)
    utils.movePage('/organizationalManagement/3/view/'+row.item.idx)
  else
    utils.movePage('/organizationalManagement/3/view/')
}
</script>

