<template>
  <Form @submit="handleForm" :validation-schema="schema">
  <div class="content-wrap">
    <NaviBox/>
    <div class="tit-group02">
      <h2 class="tit">모니터링 상세설정</h2>
    </div>

    <div class="content-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row ty08">
          <div class="txt-tit">제목<span class="imp">*</span></div>
          <div class="row">
            <c-Input :placeholder="'제목을 입력하세요'" class="size07" v-model="monitoringTitle" :name="'monitoringTitle'"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">변경 이력</div>
          <div class="row ">
            <v-btn class="txt03" @click="dialogChangeHistoryModel=true">{{ changeCnt }}</v-btn>
          </div>
        </div>
        <!--form-row-->
      </div>
      <div class="form-group col-ty inner-ty01">
        <div class="group-tit">대상자 설정</div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">참여대상</div>
          <div class="row">
            <c-btn01 :btnText="'추가'" @click.prevent="onClickMemberPopup('1')"/>
            <v-btn class="txt03">{{targetCnt}}</v-btn>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">Audit참여대상</div>
          <div class="row">
            <c-btn01 :btnText="'추가'" @click.prevent="onClickMemberPopup('3')"/>
            <v-btn class="txt03">{{ auditTargetCnt }}</v-btn>
          </div>
        </div>
      </div>
      <div class="form-group col-ty inner-ty01">
        <div class="group-tit">날짜 및 주기 설정</div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">오픈일<span class="imp">*</span></div>
          <div class="row">
            <c-InputDate v-model="startDate" :name="'startDate'" :enableTimePicker="false" readonly="" />
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">종료일<span class="imp">*</span></div>
          <div class="row">
            <c-InputDate v-model="endDate" :name="'endDate'" :enableTimePicker="false" readonly="" />
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">관찰주기</div>
          <div class="row">
            <Select v-bind="periodTypeSelect" v-model="periodType" :name="'periodType'"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">주기시작요일</div>
          <div class="row">
            <Select v-bind="periodStartDaySelect"v-model="periodStartDay" :name="'periodStartDay'"/>
            <v-btn class="txt03" @click="onClickPeriod">전체주기</v-btn>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">필수관찰횟수</div>
          <div class="row">
            <Select v-bind="periodCntSelect" v-model="periodCnt" :name="'periodCnt'"/>
          </div>
        </div>
        <!--form-row-->
      </div>
      <div class="form-group col-ty inner-ty01">
        <div class="group-tit">관찰 설정</div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">중복 관찰 허용</div>
          <div class="row">
            <v-switch v-model="duplicateYn" :name="'duplicateYn'" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">안전대화 필수저장</div>
          <div class="row">
            <v-switch v-model="timelineYn" :name="'timelineYn'" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">관찰 장소</div>
          <div class="row">
            <c-btn01 :btnText="'추가/변경'" @click.prevent="onClickPlace"/>
          </div>
        </div>
        <!--form-row-->
      </div>
      <div class="form-group col-ty inner-ty01">
        <div class="group-tit">보고서 발송 설정</div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">보고서발송일</div>
          <div class="row">
            <Select v-bind="reportDaySelect" v-model="reportDay" :name="'reportDay'"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty09">
          <div class="txt-tit">보고서자동발송</div>
          <div class="row">
            <v-switch v-model="reportAutoYn" :name="'reportAutoYn'" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
      </div>
    </div>
    <div class="content-box ty01">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt04">행동</div>
          </div>
          <div class="wr">
            <c-btnLine01 :btnText="'불러오기'" @click="onClickBehavior"/>
            <c-btn01 :btnText="'추가'"/>
          </div>
        </div>
      </div>
      <div class="sortable-group">
        <div class="sortable-header">
          <div class="col">노출순서</div>
          <div class="col">행동</div>
          <div class="col">삭제</div>
        </div>
        <Sortable
        :list="behaviorItems"
        item-key="id"
        tag="div"
        class="sortable-cont"
      >
        <template #item="{element, index}">
          <div class="draggable" :key="element.behaviorTitle">
            <div class="col" aria-hidden="true">
              <img src="@/assets/img/format-align-justify.svg" alt="">
            </div>
            <div class="col">
              {{ element.behaviorTitle }}
            </div>
            <div class="col">
              <c-btn02 :btnText="'삭제'" class="sm" @click="dialogActionDeleteModel=true"/>
            </div>
          </div>
        </template>
      </Sortable>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 :btnText="'목록'" @click.stop="onClickGoList()"/>
      </div>
      <div class="wr">
        <c-btn02 :btnText="'미리보기'" @click="onClickDetail"/>
        <c-btn02 :btnText="'삭제'" @click="dialogDeleteModel=true"/>
        <c-btn01 :btnText="'등록/수정'"/>
      </div>
    </div>
  </div>
  </Form>
  <!--일괄 대상자 변경-->
  <DialogTargetAdd2
    v-model="dialogChangeModel"
    @close="dialogChangeModel=false "
    @confirm="confirmMember"
  />
  <!--popup: 관찰장소-->
  <DialogObservationPlace
    v-model="dialogPlaceModel"
    :popTitle="'관찰 장소'"
    :popSubTitle="'모니터링 시 선택 할 수 있는 관찰 장소 목록입니다.'"
    @close="dialogPlaceModel=false"
    @confirm="confirmPlace"/>
  <!--popup: 주기별 상세날짜보기-->
  <c-dialog
    v-model="dialogPeriodModel"
    v-bind="dialogPeriodBind"
    @close="dialogPeriodModel=false"
  />
  <!--popup: 변경이력-->
  <c-dialog
    v-model="dialogChangeHistoryModel"
    v-bind="dialogChangeHistoryBind"
    @close="dialogChangeHistoryModel=false"
  />
  <!--popup: 행동불러오기-->
  <DialogCallAction
    v-model="dialogBehaviorModel"
    :pop-title="'행동불러오기'"
    @close="dialogBehaviorModel=false"
    @confirm="confirmBehavior"
  />
  <!--popup: 미리보기-->
  <DialogPreview
    v-model="dialogPreviewModel"
    :pop-title="'미리보기'"
    @close="dialogPreviewModel=false"
  />
  <!--popup: 행동삭제-->
  <c-dialog
    v-model="dialogActionDeleteModel"
    v-bind="dialogActionDeleteBind"
    @close="dialogActionDeleteModel=false"
  />
  <!--popup: 삭제-->
  <c-dialog
    v-model="dialogDeleteModel"
    v-bind="dialogDeleteBind"
    @close="dialogDeleteModel=false"
  />
  <v-snackbar v-model="snackbar" :timeout="3000"> {{ snackText }} </v-snackbar>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useCommonStore } from "@/store/common";
import {Form} from "vee-validate";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import * as yup from "yup";
import _ from 'lodash';
import { Sortable } from "sortablejs-vue3";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//validation schema
const schema = yup.object().shape({
  monitoringTitle: yup.string().required("필수 항목입니다."),
});

//bind
const snackbar = ref(false)
const snackText = ref('')
let viewIdx = route.params.idx!='null'?route.params.idx:''
const dialogChangeModel = ref(false)
const dialogPlaceModel = ref(false)
const dialogPeriodModel = ref(false)
const dialogChangeHistoryModel = ref(false)
const dialogBehaviorModel = ref(false)
const dialogPreviewModel = ref(false)
const dialogActionDeleteModel = ref(false)
const dialogDeleteModel = ref(false)
const monitoringTitle = ref('')
const startDate = ref(false)
const endDate = ref(false)
const periodType = ref({name: '1주', value: '1'})
const periodStartDay = ref({name: '월요일', value: '1'})
const duplicateYn = ref('N')
const timelineYn = ref('N')
const targetCnt = ref('0')
const monitoringTargets = ref([])
const auditTargetCnt = ref('0')
const auditTargets = ref([])
const reportAutoYn = ref('N')
const changeCnt = ref('0')
const reportDay = ref('1')
const periodCnt = ref({name: '1회', value: '1'})
const places = ref([])
const behaviorItems = ref([])

//ui
const periodTypeSelect = ({items: [{name: '1주', value: '1'}, {name: '2주', value: '2'}, {name: '3주', value: '3'}, {name: '4주', value: '4'}, {name: '5주', value: '5'}
    , {name: '6주', value: '6'}, {name: '분기', value: '11'}, {name: '반기', value: '12'}, {name: '일년', value: '13'}]})
const periodStartDaySelect = ({items: [{name: '월요일', value: '1'},{name: '화요일', value: '2'},{name: '수요일', value: '3'},{name: '목요일', value: '4'},
    {name: '금요일', value: '5'},{name: '토요일', value: '6'},{name: '일요일', value: '7'}]})
const periodCntSelect = ({items: [{name: '1', value: '1'},{name: '2', value: '2'},{name: '3', value: '3'}]})
const reportDaySelect = {items: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20','21', '22', '23', '24', '25', '26', '27', '28', '29', '30','31']}
const dialogPeriodBind = {
  dialogTit: '주기별 상세날짜 보기',
  dialogText: '1회 : 2024.02.01 ~ 2024.02.07<br>2회 : 2024.02.08 ~ 2024.02.15<br>3회 : 2024.02.16~ 2024.02.23',
  btn1Text: '확인',
  btn1Show: true,
}
const dialogChangeHistoryBind = {
  dialogTit: '변경 이력',
  dialogText: '생성 / admin / 2024.01.03 수정(제목) / admin / 2024.01.04<br>대상추가(3명) / admin / 2024.01.04<br>대상제거(1명) / admin / 2024.01.04<br>행동수정 / admin / 2024.01.04',
  btn1Text: '확인',
  btn1Show: true,
}
const dialogActionDeleteBind = {
  dialogTit: '변경 이력',
  dialogText: '삭제하시겠습니까?',
  btn1Text: '확인',
  btn1Show: true,
}
const dialogDeleteBind = {
  dialogTit: '변경 이력',
  dialogText: '삭제하시겠습니까?<br>삭제후에는 복구가 불가능합니다.',
  btn1Text: '확인',
  btn1Show: true,
}

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
  }
  else {
    if (!utils.isEmpty(viewIdx)) {
      let params = {
        idx: viewIdx
      }

      BbsService.getBbs(params).then(
        (response) => {
          monitoringTitle.value = response.result.monitoringTitle
          startDate.value = response.result.startDate ? response.result.startDate.split(' ')[0] : ''
          endDate.value = response.result.endDate ? response.result.endDate.split(' ')[0] : ''
          periodType.value = periodTypeFormat(response.result.periodType)
          periodStartDay.value = periodStartDayFormat(response.result.periodStartDay)
          duplicateYn.value = response.result.duplicateYn
          timelineYn.value = response.result.timelineYn
          targetCnt.value = response.result.targetCnt
          auditTargetCnt.value = response.result.auditTargetCnt
          reportAutoYn.value = response.result.reportAutoYn
          changeCnt.value = response.result.changeCnt
          reportDay.value = response.result.reportDay
          periodCnt.value = response.result.periodCnt
          behaviorItems.value = response.result.monitoringBehaviors
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }
  }
})

//function
function handleForm(){
  if(import.meta.env.MODE == 'development') {
    onClickGoList()
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    //코멘트리스트
    let behaviorArr = []
    behaviorItems.value.forEach(function (item){
      behaviorArr.push({idx:item.idx})
    })

    let params = {
      idx:viewIdx,
      companyIdx:companyIdx,
      monitoringTitle:monitoringTitle.value,
      startDate:startDate.value,
      endDate:endDate.value,
      periodType:periodType.value['value'],
      periodStartDay:periodStartDay.value['value'],
      duplicateYn:duplicateYn.value,
      timelineYn:timelineYn.value,
      targetCnt:targetCnt.value,
      monitoringTargets:monitoringTargets.value,
      auditTargetCnt:auditTargetCnt.value,
      monitoringAuditTargets:auditTargets.value,
      monitoringPlaces:places.value,
      reportAutoYn:reportAutoYn.value,
      changeCnt:changeCnt.value,
      reportDay:reportDay.value['value'],
      periodCnt:periodCnt.value['value'],
      monitoringBehaviors:behaviorArr,
    }

    BbsService.insertBbs(params).then(
      (response) => {
        if(response.success) {
          viewIdx = null
          storeRef.viewIdx.value = null
          utils.movePage('/monitoringManagementList')
        }
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}
function onClickGoList(){
  utils.movePage('/monitoringManagementList')
}

function onClickMemberPopup(type){
  storeRef.popupType.value = type
  storeRef.popupIdx.value = viewIdx
  dialogChangeModel.value=true
}

function confirmMember(params){
  dialogChangeModel.value=false
  if(params !== false) {
    if(storeRef.popupType.value === '1') {
      targetCnt.value = params.length
      monitoringTargets.value = params
    }
    else if(storeRef.popupType.value === '3') {
      auditTargetCnt.value = params.length
      auditTargets.value = params
    }
  }
}

function onClickPlace(){
  storeRef.popupIdx.value = viewIdx
  dialogPlaceModel.value=true
}

function confirmPlace(params){
  dialogPlaceModel.value=false
  places.value=params
}

function onClickBehavior(){
  storeRef.popupIdx.value = viewIdx
  storeRef.popupItems.value = behaviorItems.value
  dialogBehaviorModel.value=true
}

function confirmBehavior(params){
  dialogBehaviorModel.value=false
  behaviorItems.value = params
}

function onClickPeriod(){
  let companyIdx = localStorage.getItem('companyIdx')
  if(!startDate.value || !endDate.value){
    snackText.value = '날짜를 선택하세요'
    snackbar.value = true
    return;
  }
  let params = {
    startDate: startDate.value,
    endDate: endDate.value,
    periodStartDay: periodStartDay.value['value'],
    periodType: periodType.value['value']
  }
  //todo 있으면 기존 데이터로 반환
  /*
  if(storeRef.popupIdx == null)
    params = {monitoringIdx: storeRef.popupIdx.value}
   */

  BbsService.getMonitoringPeriodList(params).then(
    (response) => {
      if(response.success) {
        dialogPeriodBind.dialogText = ''
        _.forEach(response.result, function (val, key){
          dialogPeriodBind.dialogText += val.periodSeq+'회 : '+val.startDate.replace('T00:00:00','')+' ~ '+val.endDate.replace('T00:00:00','')+'<br>'
        })
        dialogPeriodModel.value = true
      }
      else
        alert(response.message)
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickDetail(){
  if(import.meta.env.MODE == 'development') {
    dialogPreviewModel.value=true
  }
  else{
    storeRef.popupIdx.value = viewIdx
    storeRef.popupItems.value = behaviorItems.value
    dialogPreviewModel.value=true
  }
}

function periodTypeFormat(param){
  if(param == 1) return {name: '1주', value: '1'}
  if(param == 2) return {name: '2주', value: '2'}
  if(param == 3) return {name: '3주', value: '3'}
  if(param == 4) return {name: '4주', value: '4'}
  if(param == 5) return {name: '5주', value: '5'}
  if(param == 6) return {name: '6주', value: '6'}
  if(param == 10) return {name: '분기', value: '10'}
  if(param == 11) return {name: '반기', value: '11'}
  if(param == 12) return {name: '일년', value: '12'}
}

function periodStartDayFormat(param){
  if(param == 1) return {name: '월요일', value: '1'}
  if(param == 2) return {name: '화요일', value: '2'}
  if(param == 3) return {name: '수요일', value: '3'}
  if(param == 4) return {name: '목요일', value: '4'}
  if(param == 5) return {name: '금요일', value: '5'}
  if(param == 6) return {name: '토요일', value: '6'}
  if(param == 7) return {name: '일요일', value: '7'}
}
</script>



<style lang="scss" scoped>
.sortable-group{
  .sortable-header ,.sortable-cont .draggable{
    .col:nth-child(1){flex-grow:0;width:15%;}
    .col:nth-child(2){justify-content:flex-start;text-align:left}
    .col:nth-child(3){flex-grow:0;width:15%;}
  }
}

</style>
