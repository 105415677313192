import api from "./api";
import Utils from "@/common/Utils";

class AdminService {
  getAdmin(params) {
    return api
      .post("/admin/getAdmin", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new AdminService();
