<template>
  <div class="input-txt-group date-ty">
    <Datepicker locale="ko" v-model="model" :format="'yyyy-MM-dd'" :model-type="'yyyy-MM-dd'" :enable-time-picker="enableTimePicker"/>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
const props = defineProps(['enableTimePicker', 'type' ,'readonly', 'disabled']);
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const model:any = defineModel()
const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}
</script>
<style lang="scss">
.input-txt-group.date-ty{
  .v-field__field{
    padding-right:3rem;
    background:url("@/assets/img/ico-date.svg") calc(100% - 1.5rem) center no-repeat;
  }
  .v-field__field:after{
    content:'';
    display:block;
    width:1.8rem;
    height:1.8rem;
    position:absolute;
    top:calc(50% - .9rem);
    right:1.5rem;
    background:url("@/assets/img/ico-date.svg") center no-repeat;
  }
  .dp__main{
    width:16rem;
    .dp__input{
      height:5rem;
      font-size:1.6rem;
      line-height:5rem
    }
  }
  .dp--menu-wrapper *{
    font-size:1.4rem;
  }
  .dp__input_icons{
    width:1.8rem;
    height:1.8rem;;
  }
  .dp__action_buttons .dp__action_select,
  .dp__range_end, .dp__range_start, .dp__active_date{
    background:var(--color-main);
  }
  .dp__today{
    border-color:var(--color-main);
  }
  .dp__action_button{
    height:3rem;
  }
}
</style>
