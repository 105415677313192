<template>
  <div class="content-wrap am-page-wrap" :class="amPage">
    <div class="navi-area">
      <div class="m-navi-top">
        <c-btn01 :btnText="'모니터링 대시보드'" @click="utils.movePage('/actionManagement')"/>
      </div>
      <NaviBox/>
    </div>
    <div class="page2">
      <div class="tit-group02">
        <h2 class="tit">타임라인</h2>
        <div class="filter-btn-group">
          <v-btn class="reset" :class="filterDiv">필터초기화</v-btn>
          <v-btn class="filter" @click="filterOpen" :class="filterDiv">필터</v-btn>
          <div class="navi-btn">
            <v-btn @click="amListOpen"/>
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="sort-group" :class="filterDiv">
          <div class="sort-row ty01">
            <div class="wl">
              <div class="inner-row">
                <div class="txt01">기간</div>
                <div class="date-sort">
                  <c-InputDate v-model="startDate" :enableTimePicker="false" readonly="" />
                  <div class="txt02">~</div>
                  <c-InputDate v-model="endDate" :enableTimePicker="false" readonly=""/>
                </div>
                <div class="date-sort">
                  <c-btn02 :btnText="'당일'" class="ty01" @click="onClickDate('today')"/>
                  <c-btn02 :btnText="'분기'" class="ty01" @click="onClickDate('week')"/>
                  <c-btn02 :btnText="'올해'" class="ty01" @click="onClickDate('month')"/>
                </div>
                <div class="date-sort">
                  <div class="txt03">지지적 피드백만 보기</div>
                  <v-switch v-model="isGoodType" inset hide-details true-value="Y" false-value="N" ></v-switch>
                </div>
              </div>
              <div class="inner-row">
                <Select v-bind="typeSelect" v-model="typeModel"/>
                <Select v-bind="deptSelect" v-model="deptModel"/>
                <Select v-bind="posSelect" v-model="posModel"/>
                <Select v-bind="monitoringSelect" v-model="monitoringModel"/>
                <Select v-bind="behaviorSelect" v-model="behaviorModel"/>
                <Select v-bind="shiftSelect" v-model="shiftModel"/>
              </div>
            </div>
            <div class="wr">
              <c-btnText01 :btnText="'축소하기'" class="ty02" @click="utils.movePage('/actionManagement')"/>
            </div>
          </div>
        </div>
        <div class="slider-group all">
          <v-slide-group
          >
            <v-slide-group-item
              v-for="(item,i) in items"
              :key="i"
              v-slot="{ isSelected, toggle }"
            >
              <v-card
                :class="item.type=='2'?'ty01':''"
                @click="toggle"
              >
                <div class="slider-item">
                  <div class="img-slider">
                    <v-carousel
                    >
                      <v-carousel-item
                        v-for="(item2,i) in item.timelineImages"
                        :key="i"
                        :src="utils.getBaseUrl('timeline', item2.filePath)"
                        cover
                        @click="onClickPreview(item2)"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                  <div @click="onClickDetail(item)">
                    <div class="txt-group">
                      <div class="txt ell01">{{ item.memberName }} / {{ item.deptName }} / {{ item.posName }}</div>
                      <div class="txt2">{{ item.regDateFormat }}</div>
                    </div>
                    <ul class="txt-group2">
                      <li v-if="item.type=='1'">
                        <div class="tit">모니터링행동</div>
                        <div class="txt ell01">{{ item.behaviorTitle }}</div>
                      </li>
                      <li v-if="item.type=='1'">
                        <div class="tit">장소</div>
                        <div class="txt ell01">{{ item.place }}</div>
                      </li>
                      <li>
                        <div class="tit">{{item.type=='1'?'구체적상황':'칭찬 사유'}}</div>
                        <div class="txt ell01">{{ item.situation }}</div>
                      </li>
                      <li v-if="item.type=='1'">
                        <div class="tit">원인</div>
                        <div class="txt ell01">{{ item.cause }}</div>
                      </li>
                      <li v-if="item.type=='1'">
                        <div class="tit">시정조치내용</div>
                        <div class="txt ell01">{{ item.action }}</div>
                      </li>
                      <li>
                        <div class="tit">{{item.type=='1'?'시정조치대상자':'칭찬대상자'}}</div>
                        <div class="txt ell01">{{ item.targetText }}</div>
                      </li>
                      <li v-if="item.actionYn=='Y'">
                        <div class="tit">조치기한</div>
                        <div class="txt ell01">{{ item.goalDate }}</div>
                      </li>
                      <li v-if="item.type=='1'">
                        <div class="tit">진행상태</div>
                        <div class="txt2 c01">{{ item.statusText }}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </div>
      </div>
    </div>
  </div>
  <AmDialog01 v-model="detailPopupModel" @close="detailPopupModel=false"/>
  <DialogImgView v-model="previewDialog" @close="previewDialog=false" :src="previewSrc"/>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TimelineService from "@/services/timeline.service";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";
import CompanyService from "@/services/company.service";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const detailPopupModel = ref(false)
const previewDialog = ref(false)
const startDate = ref('')
const endDate = ref('')
const typeModel = ref({name:'본인', value:'1'})
const deptModel = ref({name:'부서', value:''})
const posModel = ref({name:'직책', value:''})
const monitoringModel = ref({name:'모니터링', value:''})
const behaviorModel = ref({name:'행동', value:''})
const shiftModel = ref({name:'교대조', value:''})
const allItems = ref([])
const items = ref([])
const previewSrc = ref('')
const isGoodType = ref("N")

//ui
const typeSelect = {items: [{name:'본인', value:'1'},{name:'부서', value:'2'}]}
const deptSelect = ref({items: [{name:'부서', value:''}]})
const posSelect = ref({items: [{name:'직책', value:''}]})
const monitoringSelect = ref({items: [{name:'모니터링', value:''}]})
const behaviorSelect = ref({items: [{name:'행동', value:''}]})
const shiftSelect = {items: [{name:'교대조', value:''},{name:'교대조 Y', value:'Y'},{name:'교대조 N', value:'N'}]}

//watch
watch(startDate, (newObj) => {
  onClickSearch()
})
watch(endDate, (newObj) => {
  onClickSearch()
})
watch(typeModel, (newObj) => {
  onClickSearch()
})
watch(deptModel, (newObj) => {
  onClickSearch()
})
watch(posModel, (newObj) => {
  onClickSearch()
})
watch(monitoringModel, (newObj) => {
  onClickSearch()
})
watch(behaviorModel, (newObj) => {
  onClickSearch()
})
watch(shiftModel, (newObj) => {
  onClickSearch()
})
watch(isGoodType, (newObj) => {
  if(newObj == 'Y')
    items.value = _.filter(allItems.value, {type:'2'})
  else
    items.value = allItems.value
})

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: '1',
        monitoringTitle: '모니터링1',
        memberName: '김동임',
        monitoringYn: 'Y',
        timelineYn: 'N',
        regDateFormat: '2024-01-25 13:28:40',
        moData: '생산1팀/과장',
        timelineImages:[{src:'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'}]
      },
    ]
  }
  else {
    const user = JSON.parse(localStorage.getItem('user'))
    let params = {idx: 0, companyIdx: user.companyIdx}
    CompanyService.getCompanyTreeList(params).then(
      (response) => {
        let deptList = _.filter(response.result, {treeType:'dept'})
        let posList = _.filter(response.result, {treeType:'pos'})
        _.forEach(deptList, function (item){
          deptSelect.value.items.push({name:item.treeName, value:item.idx})
        })
        _.forEach(posList, function (item){
          posSelect.value.items.push({name:item.treeName, value:item.idx})
        })
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )

    BbsService.getMonitoringFilter({}).then(
      (response) => {
        //모니터링필터
        let monitoringList = response.result.monitoringList
        _.forEach(monitoringList, function (item){
          monitoringSelect.value.items.push({name:item.monitoringTitle, value:item.idx})
        })
        //행동필터
        let behaviorList = response.result.behaviorList
        _.forEach(behaviorList, function (item){
          behaviorSelect.value.items.push({name:item.behaviorTitle, value:item.idx})
        })
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )

    onClickSearch()
  }
})

//모니터링 메뉴 열고 닫기
const amPage = ref('naviOff')
function amListOpen(){
  if(amPage.value == 'naviOff')
    amPage.value = 'naviOn'
  else
    amPage.value = 'naviOff'
}
//필터 메뉴 열고 닫기
const filterDiv = ref('off')
function filterOpen(){
  if(filterDiv.value == 'off')
    filterDiv.value = 'on'
  else
    filterDiv.value = 'off'
}

function onClickPreview(item){
  previewSrc.value = utils.getBaseUrl('timeline', item.filePath)
  previewDialog.value = true
}

function onClickDetail(item){
  storeRef.popupIdx.value = item.idx
  detailPopupModel.value = true
}

function onClickDate(type){
  const end = dayjs().format('YYYY-MM-DD')
  let start = dayjs().format('YYYY-MM-DD')
  if(type == 'week')
    start = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  else if(type == 'month')
    start = dayjs().subtract(4, 'month').format('YYYY-MM-DD')

  startDate.value = start.toString()
  endDate.value = end.toString()
}

function onClickSearch(){
  const user = JSON.parse(localStorage.getItem('user'))
  let params = {
    startDate:startDate.value,
    endDate:endDate.value,
    filterType:typeModel.value['value'],
    deptIdx:deptModel.value['value'],
    posIdx:posModel.value['value'],
    monitoringIdx:monitoringModel.value['value'],
    behaviorIdx:behaviorModel.value['value'],
    shiftYn:shiftModel.value['value'],
  }

  TimelineService.getTimelineList(params).then(
    (response) => {
      //타임라인
      allItems.value = response.result
      _.forEach(allItems.value, function(val, key) {
        val.typeText = val.type=='1'?'교정적 안전대화':'지지적 안전대화'
        val.actionYnText = val.actionYn=='Y'?'필요':'불필요'
        val.targetText = '없음'
        if(val.targetType == '2') val.target = val.targetDept
        else if(val.targetType == '3') val.target = val.targetMember
        val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(val.goalDate).format('YYYY-MM-DD')
        val.statusText = '준비'
        if(val.status == '2')
          val.statusText = '진행중'
        else if(val.status == '3')
          val.statusText = '보류'
        else if(val.status == '4')
          val.statusText = '완료'
      })

      if(isGoodType.value == 'Y')
        items.value = _.filter(allItems.value, {type:'2'})
      else
        items.value = allItems.value
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}
</script>


<style lang="scss">
</style>
