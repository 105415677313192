<template>
  <Form @submit="handleForm" :validation-schema="schema">
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">타입<span class="imp">*</span></div>
          <div class="row">
            <div class="radio-group">
              <v-radio-group v-model="type" :name="'type'" >
                <v-radio color="indigo" label="시스템공지" value="1"></v-radio>
                <v-radio color="indigo" label="그룹공지" value="2"></v-radio>
                <v-radio color="indigo" label="회사공지" value="3"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">제목<span class="imp">*</span></div>
          <div class="row">
            <c-Input v-bind="inpTitle" v-model="title" :name="'title'" class="size01"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">내용<span class="imp">*</span></div>
          <div class="row">
            <div class="txt">
              <quill-editor
                v-model:value="content"
                :options="editorOption"
                @change="onEditorChange($event)"
              ></quill-editor>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">게시시작일</div>
          <div class="row">
            <c-InputDate v-bind="inpStartDate" v-model="startDate" :name="'startDate'" class="size02" readonly=""/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">게시종료일</div>
          <div class="row">
            <c-InputDate v-bind="inpEndDate" v-model="endDate" :name="'endDate'" class="size02" readonly=""/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">노출여부</div>
          <div class="row">
            <v-switch v-model="showYn" inset hide-details true-value="Y" false-value="N"></v-switch>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">푸시발송</div>
          <div class="row">
            <v-switch v-model="pushYn" inset hide-details true-value="Y" false-value="N"></v-switch>
            <div class="txt02" v-if="pushSendDate">전송일자 : {{pushSendDate}}</div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">메일발송</div>
          <div class="row">
            <v-switch v-model="mailYn" inset hide-details true-value="Y" false-value="N"></v-switch>
            <div class="txt02" v-if="mailSendDate">전송일자 : {{mailSendDate}}</div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">등록일시</div>
          <div class="row">
            <div class="txt02">{{inpUploadVal}}</div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">수정일시</div>
          <div class="row">
            <div class="txt02">{{inpModifyVal}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 v-bind="btnPageList" @click="onClickGoList"/>
      </div>
      <div class="wr">
        <c-btn02 v-bind="btnPageDel" @click="dialogPageDelModel=true"/>
        <c-btn01 v-bind="btnPageModify"/>
      </div>
    </div>
  </div>
  </Form>
  <!--삭제버튼-->
  <c-dialog v-model="dialogPageDelModel"
            v-bind="dialogPageDelBind"
            @close="dialogPageDelModel=false"
  />
</template>
<style lang="scss" >
</style>
<script setup lang="ts">
import { ref } from 'vue'
import BoardService from "@/services/board.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import {Form} from "vee-validate";
import * as yup from "yup";
import { quillEditor } from 'vue3-quill'
import 'quill/dist/quill.core.css'
import {useRoute} from "vue-router";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//validation schema
const schema = yup.object().shape({
  title: yup.string().required("필수 항목입니다."),
});

//bind
const boardIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value
const type = ref('1')
const title = ref('')
const content = ref('')
const content_ = ref('')
const startDate = ref('')
const endDate = ref('')
const mailSendDate = ref('')
const pushSendDate = ref('')
const showYn = ref('N')
const pushYn = ref('N')
const mailYn = ref('N')
const inpUploadVal = ref('')
const inpModifyVal = ref('')
const editorOption = {
  placeholder: '내용을 입력해주세요...',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'image', 'video']
    ]
  }
}

//ui
const dialogPageDelModel = ref(false)
const dialogPageDelBind = {
  dialogTit: '삭제',
  dialogText: '관리자 삭제 시 일반사용자로 변경됩니다.  적용하시겠습니까?',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const radioType = ref('시스템공지');
const inpTitle = {
  placeholder: '제목을 입력하세요',
  type:'text'
}
const inpStartDate={
  placeholder: '시작일',
}
const inpEndDate={
  placeholder: '종료일',
}
const btnPageList = {
  btnText: '목록',
}

const btnPageDel = {
  btnText: '삭제',
}
const btnPageModify = {
  btnText: '등록/수정',
  btnType: 'submit'
}

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
  }
  else {
    if (boardIdx) {
      let params = {
        idx: boardIdx
      }

      BoardService.getBoard(params).then(
        (response) => {
          type.value = response.result.type
          title.value = response.result.title
          content.value = response.result.content
          startDate.value = response.result.startDate
          endDate.value = response.result.endDate
          showYn.value = response.result.showYn
          pushYn.value = response.result.pushYn
          mailYn.value = response.result.mailYn
          inpUploadVal.value = response.result.regDate
          inpModifyVal.value = response.result.modDate
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }
  }
})

//function
function handleForm(){
  if(import.meta.env.MODE == 'development') {
    utils.movePage('/organizationalManagement/4/list')
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    if(!companyIdx)
      utils.movePage('/login')

    let params = {
      idx: boardIdx,
      companyIdx:companyIdx,
      type: type.value,
      title: title.value,
      content: content.value,
      startDate: startDate.value,
      endDate: endDate.value,
      showYn: showYn.value,
      pushYn: pushYn.value,
      mailYn: mailYn.value,
    }

    BoardService.insertBoard(params).then(
      (response) => {
        if(response.success)
          onClickGoList();
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function onClickGoList(){
  utils.movePage('/organizationalManagement/4/list')
}

const onEditorChange = ({ quill, html, text }) => {
  content_.value = html
}
</script>

