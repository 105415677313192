<template>
  <div class="gnb">
    <div class="wl">
      <h1 class="logo">
        <img src="@/assets/img/logo1.svg" alt="impactus">
      </h1>
      <div class="tit">
        <span>{{clientName}}</span>
        <span>{{companyName}}</span>
      </div>
    </div>
    <div class="wr">
      <div class="user">
        <div class="txt-group">
          <div class="txt-name">{{memberName}}</div>
          <div class="txt-rank">{{memberOrg}}</div>
        </div>
        <v-btn @click="DialogUserInfoModel=true">{{memberId}}</v-btn>
      </div>
      <div class="set">
        <v-menu
          transition="slide-y-transition"
          location="bottom center"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
            >
            </v-btn>
          </template>
          <v-list class="set-menu-list">
            <v-list-item
              v-for="(item, i) in setItems"
              :key="i"
            >
              <v-list-item-title>
                <button type="button" @click="onClickSelect(item.link)">
                  {{ item.title }}
                </button>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="alarm">
        <i class="new-dot" v-if="isAlarm"></i>
        <v-btn @click="utils.movePage('/alarm')"/>
      </div>
      <div class="lang">
        <v-select
          v-model="langModel"
          :items="langItems"
        ></v-select>
      </div>
    </div>
  </div>
  <DialogUserInfo v-model="DialogUserInfoModel" @close="DialogUserInfoModel=false"/>
</template>
<script lang="ts" setup>
import {computed, ref, watch} from 'vue'
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import TokenService from "@/services/token.service";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const DialogUserInfoModel = ref(false)
const clientName = ref('');
const companyName = ref('');
const memberName = ref('')
const memberOrg = ref('')
const memberId = ref('')
const isAlarm = ref(false)

//ui
const langItems = ['KR', 'EN']
const langModel = ref('KR')
const setItems = ref([])

//watch
watch(storeRef.user, async (newData, oldData) => {
  let user = JSON.parse(localStorage.getItem('user'));
  companyName.value = user.companyName?user.companyName:'Admin'
  clientName.value = user.clientName?user.clientName:'Admin'
  memberName.value = user.memberName
  memberOrg.value = user.deptName?user.deptName:''+'/'+user.posName?user.posName:''
  memberId.value = user.memberId.substring(0,3)
},
{
    deep: true,
    immediate:true
  }
)

watch(storeRef.isAlarm, async (newData, oldData) => {
    isAlarm.value = newData
  },
  {
    immediate:true
  }
)

//hook
onUpdated (() => {
  const user = JSON.parse(localStorage.getItem('user'))
  if(storeRef.user.value === null){
    storeRef.user.value = user
  }
  if(utils.isMobile()){
    setItems.value = [
      { title: '로그아웃', link:'logout'},
    ]
  }
  else if(user.roles === 'ADMIN1')
    setItems.value = [
      { title: '고객사관리', link:'clientManagement'},
      { title: '그룹관리', link:'groupManagement'},
      { title: '언어설정', link:'languageSetting'},
      { title: '로그아웃', link:'logout'},
    ]
  else if(user.roles === 'ADMIN2')
    setItems.value = [
      { title: '그룹관리', link:'groupManagement'},
      { title: '언어설정', link:'languageSetting'},
      { title: '로그아웃', link:'logout'},
    ]
  else if(user.roles === 'ADMIN3')
    setItems.value = [
      { title: '언어설정', link:'languageSetting'},
      { title: '로그아웃', link:'logout'},
    ]
  else if(user.roles === 'USER')
    setItems.value = [
      { title: '로그아웃', link:'logout'},
    ]
})

//function

function onClickSelect(link){
  if(link === 'logout') {
    TokenService.removeUser()
    utils.movePage('/login')
  }
  else
    utils.movePage(link)
}
</script>
<style lang="scss">
.gnb{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:8rem;
  background:var(--gnb-bg);
  border-bottom:1px solid var(--gnb-boder);
  z-index:2000;
  display:flex;
  justify-content:space-between;
  align-items:center;
  .wl{
    display:flex;
    align-items:center;
    .logo{
      margin-left:3rem;
    }
    .tit{
      margin-left:1.4rem;
      span{
        position:relative;
        display:inline-block;
        color:var(--gnb-txt);
        font-size:1.6rem;
        font-weight:700;
      }
      span + span{
        padding-left:2.2rem;
      }
      span + span:before{
        content:'';
        display:block;
        width:.2rem;
        height:1.6rem;
        position:absolute;
        top:.2rem;
        left:1rem;
        opacity:.5;
        background:var(--gnb-txt);
      }
    }
  }
  .wr{
    display:flex;
    align-items:center;
    padding-right:2rem;
    .set, .alarm, .user{
      margin-left:.7rem;
      .v-btn{
        box-shadow:none;
        min-width:3.4rem;
        padding:0;
        border:1px solid var(--gnb-btn-line);
        border-radius:100%;
        & > *{border-radius:100%;width:3.4rem;height:3.4rem}
        @supports selector(:focus-visible) {
          .v-btn::after {
            border:0
          }
        }
      }
    }
    .user{
      display:flex;
      align-items:center;
      .txt-group{
        text-align:right;
        .txt-name{
          font-size:1.4rem;
          line-height:2rem;
          color:var(--txt-black);
        }
        .txt-rank{
          font-size:1.2rem;
          line-height:1.4rem;
          color:var(--txt-gray);
        }
      }
      .v-btn{
        margin-left:.8rem;
        background:var(--gnb-btn-bg02);
        .v-btn__content{
          font-size:1.4rem;
          color:var(--txt-white);
        }
      }
    }
    .set{
      .v-btn{
        background:var(--gnb-btn-bg) url("@/assets/img/ico-set.svg")center no-repeat;
      }
    }
    .alarm{
      position:relative;
      .new-dot{
        position:absolute;
        top:0;
        right:0;
        width:.8rem;
        height:.8rem;
        background:var(--txt-red);
        border-radius:100%;
        z-index:1;
      }
      .v-btn{
        background:var(--gnb-btn-bg) url("@/assets/img/ico-alarm.svg")center no-repeat;
      }
    }
    .lang{
      margin-left:1.3rem;
      //폰트 크기
      .v-field{
        font-size:1.4rem;
        font-weight:700;
        color:#64748B;
      }
      //기본 배경색
      .v-field--variant-filled .v-field__overlay{
        opacity:1;
        background-color:var(--gnb-btn-bg);
      }
      //포커스 배경색
      .v-field--variant-filled.v-field--focused .v-field__overlay{
        opacity:0.1;
      }
      .v-field__input{
        min-height:4rem;
        padding-top:.9rem;
      }
      .v-input--density-default{
        --v-input-control-height:4rem;
        --v-input-padding-top:.9rem
      }
      .v-input--density-default .v-field--variant-solo,
      .v-input--density-default .v-field--variant-solo-inverted,
      .v-input--density-default .v-field--variant-solo-filled,
      .v-input--density-default .v-field--variant-filled{
        --v-input-control-height:4rem;
      }
      .v-input--density-default .v-field--variant-outlined,
      .v-input--density-default .v-field--single-line,
      .v-input--density-default .v-field--no-label{
        --v-field-padding-bottom:.9rem;
      }
      //기본 라인
      .v-field--variant-filled{
        border-radius:10rem;
      }
      .v-field--variant-filled .v-field__outline::before{
        border:1px solid var(--gnb-btn-line);
        opacity: 1;
        border-radius:10rem;

      }
      //포커스 라인
      .v-field--variant-filled .v-field__outline::after{
        border:1px solid var(--gnb-btn-line-focus);
        transition:none;
        border-radius:10rem;
      }
      .v-input__details{display:none}
    }

  }
}
.v-list.set-menu-list{
  margin-top:1rem;
  border:1px solid var(--menu-line);
}
@media (max-width: 853px){
  .gnb{
    height:6rem;
    background:var(--color-body-bg);
    border-bottom:0;
    .wl{
      .logo{
        margin-left:1rem;
        img{
          width:10rem;
        }
      }
      .tit{
        display:none;
      }
    }
    .wr{
      padding-right:1rem;
      .user, .alarm, .set{
        margin-left:.5rem ;
        .v-btn{
          min-width:3rem;
          --v-btn-height:3rem;
          border:0;
          & > *{
            width:3rem;
            height:3rem;
          }
        }
      }
      .user{
        .txt-group{
          display: none;
        }
        .v-btn .v-btn__content{
          font-size:1.1rem;
        }
      }

      .set{
        .v-btn{
          background-color:transparent;
        }
      }
      .alarm{
        .v-btn{
          background-color:transparent;
        }
        .new-dot{
          top:.2rem;
          right:.2rem;
          width:.4rem;
          height:.4rem;
        }
      }
      .lang{
        .v-field--variant-filled .v-field__overlay{
          background-color:var(--color-white);
        }
      }
    }
  }
}
</style>
