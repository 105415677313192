<template>
  <div class="mb-page-wrap2">
    <div class="tit-group">
      <h2 class="tit">My Board</h2>
    </div>
    <div class="tit-group02 ty01">
      <h2 class="tit">시정조치</h2>
    </div>
    <div class="content-box bg-none">

      <div class="content-inner-box-wrap">
        <div class="content-inner-box">
          <div class="form-wrap">
            <div class="form-group col-ty">
              <div class="tit-group">
                <div class="tit02">상세정보</div>
              </div>
              <!--form-row-->
              <div class="form-row" v-if="item.type=='1'">
                <div class="txt-tit">행동</div>
                <div class="row">{{ item.behaviorTitle }}</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">등록자</div>
                <div class="row">{{ item.memberName }} / {{ item.deptName }} / {{ item.posName }}</div>
              </div>
              <!--form-row-->
              <div class="form-row" v-if="item.type=='1'">
                <div class="txt-tit">장소</div>
                <div class="row">{{ item.place }}</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">{{item.type=='1'?'구체적상황':'칭찬 사유'}}</div>
                <div class="row">{{ item.situation }}</div>
              </div>
              <!--form-row-->
              <div class="form-row" v-if="item.type=='1'">
                <div class="txt-tit">원인</div>
                <div class="row">{{ item.cause }}</div>
              </div>
              <!--form-row-->
              <div class="form-row" v-if="item.type=='1'">
                <div class="txt-tit">시정조치 내용</div>
                <div class="row">{{ item.action }}</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">{{item.type=='1'?'시정조치대상자':'칭찬대상자'}}</div>
                <div class="row">{{ item.targetText }}</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">등록일</div>
                <div class="row">{{ dayjs(item.regDate).format('YYYY-MM-DD') == 'Invalid Date' ? '' : dayjs(item.regDate).format('YYYY-MM-DD HH.mm.ss') }}</div>
              </div>
              <!--form-row-->
              <div class="form-row" v-if="item.actionYn=='Y'">
                <div class="txt-tit">조치기한</div>
                <div class="row">{{ dayjs(item.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '' : dayjs(item.goalDate).format('YYYY-MM-DD') }}</div>
              </div>

              <div class="img-slider02">
                <v-carousel
                  v-model="timelineImageModel"
                >
                  <div class="img-wrap ty01">
                    <v-carousel-item
                      v-for="(image,i) in item.timelineImages"
                      :key="i"
                      :src="utils.getBaseUrl('timeline', image.filePath)"
                      cover
                      @click="onClickPreview(image, 'timeline')"
                    ></v-carousel-item>
                  </div>
                </v-carousel>
              </div>
            </div>
            <div class="bottom-btn-group" v-if="item.type=='1'">
              <div class="radio-group2 ty01">
                <v-radio-group v-model="item.status" @change="onChangeStatus" :disabled="item.memberIdx==user.memberIdx?false:true">
                  <v-radio label="진행중" value="2"></v-radio>
                  <v-radio label="보류" value="3"></v-radio>
                  <v-radio label="완료" value="4"></v-radio>
                </v-radio-group>
              </div>
              <div class="wr">
                <c-btn02 :btnText="'대상변경'" class="ty01" @click="onClickTarget" :disabled="item.memberIdx==user.memberIdx?false:true"/>
              </div>
            </div>
          </div>
        </div>
        <div class="content-inner-box ty02">
          <div class="tit-group mo-ty01">
            <h4 class="tit">히스토리</h4>
          </div>
          <div class="form-wrap">
            <div class="form-wrap-inner">
              <div class="tit-group">
                <h4 class="tit">히스토리</h4>
              </div>
              <div class="history-group scrollbar">
                 <div class="box"
                      v-for="(comment,i) in item.timelineLogs"
                      :key="i" >
                  <div class="txt-group">
                    <div class="txt">{{dayjs(comment.regDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(comment.regDate).format('YYYY-MM-DD HH:mm:ss')}}</div>
                    <div class="txt2">
                      <div class="tag-icon-group">
                        <!-- changeType 변경상태 1:생성 2:코멘트 3:상태변경 4:전달 5:코멘트삭제 -->
                        <!-- change_status 변경상태 상태 1:준비 2:진행중 3:보류 4:완료 -->
                        <div class="tag-icon ty01" v-if="comment.changeType == '1'">생성</div>
                        <div class="tag-icon ty02" v-if="comment.changeType == '2'">코멘트</div>
                        <div class="tag-icon ty03" v-if="comment.changeType == '3'">
                          {{comment.changeStatus=='1'?'준비':''}}
                          {{comment.changeStatus=='2'?'진행중':''}}
                          {{comment.changeStatus=='3'?'보류':''}}
                          {{comment.changeStatus=='4'?'완료':''}}
                        </div>
                        <div class="tag-icon ty04" v-if="comment.changeType == '4'">전달</div>
                        <div class="tag-icon ty02" v-if="comment.changeType == '5'">코멘트삭제</div>
                      </div>

                      <span class="t1">{{ comment.memberName }} / {{ comment.deptName }} / {{ comment.posName }}</span>
                      <div class="inner" v-if="comment.changeType == '4'">
                        <span class="ico-arr" aria-hidden="true"></span>
                        <span class="t2" v-if="comment.targetType == '3'">{{ comment.targetMemberName }} / {{ comment.targetDeptName }} / {{ comment.targetPosName }}</span>
                        <span class="t2" v-if="comment.targetType == '2'">{{ comment.targetName }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="txt-group2" v-if="comment.comment || (comment.changeStatus=='3'&&comment.changeType=='3')">
                    <div class="txt">{{comment.changeStatus=='3'&&comment.changeType=='3'?comment.logMsg:comment.comment}}</div>
                    <div class="img-group" v-if="comment.filePath">
                      <div class="item">
                        <v-btn @click="onClickPreview(comment, 'comment')">
                          <img :src="utils.getBaseUrl('comment', comment.filePath)">
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <InputComment :idx="item.idx" @confirmComment="confirmComment"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogImgView v-model="previewDialogModel" @close="previewDialogModel=false" :src="previewSrc"/>
  <DialogTargetAdd4 v-model="targetDialogModel" @close="targetDialogModel=false" @confirm="confirmTarget"/>
  <DialogReason v-model="delayDialogModel" @close="delayDialogModel=false" @confirm="confirmDelay"/>
  <v-snackbar v-model="snackbar" :timeout="3000" > {{ snackText }} </v-snackbar>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()
let viewIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value

//bind
const previewDialogModel = ref(false)
const targetDialogModel = ref(false)
const delayDialogModel = ref(false)
const delayText = ref('')
const item:any = ref({})
const previewSrc = ref('')
const user:any = ref('')
const timelineImageModel = ref(0)
const prevStatus = ref(0)
const snackbar = ref(false)
const snackText = ref('')

//hook
onMounted(() => {
  user.value = JSON.parse(localStorage.getItem('user'))
  if(import.meta.env.MODE == 'development'){
    item.value=
      {
        "idx": 11,
        "companyIdx": 3,
        "monitoringIdx": 6,
        "answerIdx": 34,
        "memberIdx": 34,
        "deptIdx": 169,
        "posIdx": 165,
        "type": "1",
        "actionYn": "Y",
        "targetType": "3",
        "targetDeptIdx": 0,
        "targetMemberIdx": 34,
        "goalDate": "2025-01-20 02:35:53.0",
        "place": "",
        "situation": "1111",
        "cause": "2222",
        "action": "3333",
        "status": "3",
        "delayReason": null,
        "modIdx": 34,
        "regIdx": 0,
        "modDate": "2025-01-05 02:35:53.30961",
        "modDateFormat": "2025.01.05",
        "regDate": "2025-01-05 00:44:06.570349",
        "regDateFormat": "2025.01.05",
        "tempSeq": 1,
        "isTemp": "N",
        "timelineImages": [],
        "timelineComments": [],
        "memberName": "관리자",
        "deptName": "부서2",
        "targetMemberName": "관리자",
        "targetDeptName": null,
        "posName": "뭉뭉이",
        "behaviorTitle": null
      }
  }
  else{
    onClickSearch()
  }
})

//function
function onClickSearch(){
  let params = {
    idx:viewIdx
  }

  TimelineService.getTimelineList(params).then(
    (response) => {
      //타임라인
      if(response.result.length > 0)
        item.value = response.result[0]
      item.value.typeText = item.value.type=='1'?'교정적 안전대화':'지지적 안전대화'
      item.value.actionYnText = item.value.actionYn=='Y'?'필요':'불필요'
      item.value.targetText = '없음'
      if(item.value.targetType == '2') item.value.target = item.value.targetDeptName
      else if(item.value.targetType == '3') item.value.target = item.value.targetMemberName
      item.value.goalDate = dayjs(item.value.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(item.value.goalDate).format('YYYY-MM-DD')
      item.value.statusText = '준비'
      if(item.value.status == '2')
        item.value.statusText = '진행중'
      else if(item.value.status == '3')
        item.value.statusText = '보류'
      else if(item.value.status == '4')
        item.value.statusText = '완료'
      prevStatus.value = item.value.status
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickPreview(item, type){
  previewSrc.value = utils.getBaseUrl(type, item.filePath)
  previewDialogModel.value = true
}

function onChangeStatus(){
  if(prevStatus.value == item.value.status)
    return

  if(item.value.status == '3'){
    item.value.status = prevStatus.value
    delayDialogModel.value = true
    return
  }
  else{
    item.value.targetType = null
    item.value.targetDeptIdx = 0
    item.value.targetMemberIdx = 0
    item.value.changeType = '3'
    changeStatus('')
  }
}

function changeStatus(delayReason){
  let params = {
    idx:viewIdx,
    status:item.value.status,
    changeType:item.value.changeType,
    delayReason:delayReason,
    targetType:item.value.targetType,
    targetMemberIdx:item.value.targetMemberIdx,
    targetDeptIdx:item.value.targetDeptIdx,
  }

  TimelineService.updateTimeline(params).then(
    (response) => {
      prevStatus.value = item.value.status
      delayDialogModel.value=false
      onClickSearch()
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function confirmComment(params){
  //item.value.timelineComments.unshift(params)
  onClickSearch()
}

function onClickDeleteComment(comment){
  let params = {
    idx:comment.idx
  }

  TimelineService.deleteTimelineComment(params).then(
    (response) => {
      item.value.timelineComments = _.pull(item.value.timelineComments, comment)
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function confirmDelay(params){
  item.value.status = '3'
  item.value.changeType = '3'
  item.value.targetType = null
  item.value.targetDeptIdx = 0
  item.value.targetMemberIdx = 0
  changeStatus(params)
}

function onClickTarget(){
  if(item.memberIdx==user.memberIdx) {
    storeRef.popupType.value = item.value.targetType
    //2부서 3개인
    storeRef.popupIdx.value = item.value.targetType == '3' ? item.value.targetMemberIdx : item.value.targetDeptIdx
    targetDialogModel.value = true
  }
}

function confirmTarget(params){
  if(params.length <= 0){
    snackText.value = '선택 항목이 없습니다'
    snackbar.value = true
  }
  else {
    item.value.status = '1' //시정조치상태 1:준비 2:진행중 3:보류 4:완료
    item.value.changeType = '4' //로그에 저장 할 상태 1:생성 2:코멘트 3:상태변경 4:전달 5:코멘트삭제
    item.value.targetType = params[0].targetType //대상자 1:없음 2:부서 3:구성원
    if(params[0].targetType == '2')
      item.value.targetDeptIdx = params[0].idx
    else
      item.value.targetMemberIdx = params[0].idx
    changeStatus('')
  }
}
</script>



<style lang="scss" scoped>
</style>
