<template>
  <div class="content-box bg-none sc-page-wrap">
    <div class="top-cont">
      <div class="switch-group">
        <div class="col">
          <div class="tit">안전관찰</div>
          <v-switch v-model="bbsModel" inset hide-details @change="changeSwitch"/>
        </div>
        <div class="col ty01" v-if="false">
          <div class="tit">안전리더십</div>
          <v-switch v-model="leadershipModel" inset hide-details @change="changeSwitch"/>
        </div>
        <div class="col ty02">
          <div class="tit">시정조치</div>
          <v-switch v-model="timelineModel" inset hide-details @change="changeSwitch"/>
        </div>
      </div>
      <div>
        <div class="selected-group3">
          <v-select
            v-model="calenderModel"
            :items="CalenderItems"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="content-inner-box">
      <div class="list-group4 scrollbar">
        <div class="box" v-if="todoList.length > 0">
          <div class="txt-date">TODO</div>
          <div class="list-inner">
            <div class="header-group">
              <div class="col">제목</div>
              <div class="col">단계</div>
              <div class="col">진행</div>
              <div class="col">기간</div>
            </div>
            <ul>
              <li
              v-for="(item, i) in todoList"
              :key="'todo'+i"
              :class="getClass(item)">
                <v-btn  @click="onClickItem(item)">
                  <span class="t1">{{ item.title }}</span>
                  <span class="t2">{{ item.type == 'b'?item.periodSeq+'회차':'' }}</span>
                  <span class="t3">{{ getStatus(item) }}</span>
                  <span class="t4">{{ item.type == 'b'?dayjs(item.startDate).format('YYYY-MM-DD')+'~'+dayjs(item.endDate).format('YYYY-MM-DD'):item.formatDate}}</span>
                </v-btn>
              </li>
            </ul>
          </div>
        </div>
        <div class="box" v-if="endList.length > 0">
          <div class="txt-date">미완료</div>
          <div class="list-inner">
            <div class="header-group">
              <div class="col">제목</div>
              <div class="col">단계</div>
              <div class="col">진행</div>
              <div class="col">기간</div>
            </div>
            <ul>
              <li
                v-for="(item, i) in endList"
                :key="'todo'+i"
                :class="getClass(item)">
                <v-btn  @click="onClickItem(item)">
                  <span class="t1">{{ item.title }}</span>
                  <span class="t2">{{ item.type == 'b'?item.periodSeq+'회차':'' }}</span>
                  <span class="t3">{{ getStatus(item) }}</span>
                  <span class="t4">{{ item.type == 'b'?dayjs(item.startDate).format('YYYY-MM-DD')+'~'+dayjs(item.endDate).format('YYYY-MM-DD'):dayjs(item.goalDate).format('YYYY-MM-DD')}}</span>
                </v-btn>
              </li>
            </ul>
          </div>
        </div>
        <div class="box" v-if="completeList.length > 0">
          <div class="txt-date">완료</div>
          <div class="list-inner">
            <div class="header-group">
              <div class="col">제목</div>
              <div class="col">단계</div>
              <div class="col">진행</div>
              <div class="col">기간</div>
            </div>
            <ul>
              <li
                v-for="(item, i) in completeList"
                :key="'todo'+i"
                :class="getClass(item)">
                <v-btn  @click="onClickItem(item)">
                  <span class="t1">{{ item.title }}</span>
                  <span class="t2">{{ item.type == 'b'?item.periodSeq+'회차':'' }}</span>
                  <span class="t3">{{ getStatus(item) }}</span>
                  <span class="t4">{{ item.type == 'b'?dayjs(item.startDate).format('YYYY-MM-DD')+'~'+dayjs(item.endDate).format('YYYY-MM-DD'):dayjs(item.goalDate).format('YYYY-MM-DD')}}</span>
                </v-btn>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import CalendarService from "@/services/calendar.service";
import utils from "@/common/Utils";
import _ from 'lodash';
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import dayjs from "dayjs";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const calenderModel = ref('주별')
const bbsModel = ref(true)
const leadershipModel = ref(true)
const timelineModel = ref(true)
let allList = []
const todoList = ref([])
const endList = ref([])
const completeList = ref([])

//ui
const CalenderItems = ['주별', '월별']

//watch
watch(calenderModel, (newDate) => {
  utils.movePage('/safetyCalender2')
});

//hook
onMounted((): void => {
  if(import.meta.env.MODE == 'development'){
  }
  else {
    CalendarService.getCalendarList({calendarType: 'w'}).then(
      (response) => {
        allList = []
        _.forEach(response.result.bbsList, function (item,i) {
          allList.push({
            idx: item.idx,
            id: 'bbs' + i,
            startDate: toDate(item.startDate),
            endDate: toDate(item.endDate),
            formatDate: toDate(item.startDate).toISOString().split('T')[0],
            classes: ["sort01"],
            title: item.monitoringTitle + '(' + item.periodSeq + ')',
            tooltip: item.monitoringTitle,
            type: 'b',
            dateStatus: item.dateStatus,
            answerCnt: item.answerCnt,
            status: item.status,
            periodSeq: item.periodSeq})
        })
        _.forEach(response.result.timelineList, function (item,i) {
          allList.push({
            idx: item.idx,
            id: 'timeline' + i,
            startDate: toDate(item.goalDate),
            formatDate: toDate(item.goalDate).toISOString().split('T')[0],
            classes: ["sort03"],
            title: item.situation,
            tooltip: item.situation,
            type: 't',
            dateStatus: item.dateStatus,
            answerCnt: 0, //timeline은 0
            status: item.status})
        })

        todoList.value = _.filter(allList, item=>(item.dateStatus=='ready' || item.dateStatus=='warning')&&(item.answerCnt==0 && item.status!=4))
        endList.value = _.filter(allList, item=>(item.dateStatus=='end')&&(item.answerCnt==0 && item.status!=4))
        completeList.value = _.filter(allList, item=>(item.dateStatus=='end')&&(item.answerCnt>0 || item.status==4))
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
})

//function
const changeSwitch = ()=>{
  let isBbs = bbsModel.value
  let isTimeline = timelineModel.value
  let newList = []
  if(isBbs && !isTimeline)
    newList = _.filter(allList, item => item.type === 'b')
  else if(!isBbs && isTimeline)
    newList = _.filter(allList, item => item.type === 't')
  else if(isBbs && isTimeline)
    newList = _.filter(allList, item => item.type === 'b' || item.type === 't')

  todoList.value = _.filter(newList, item=>(item.dateStatus=='ready' || item.dateStatus=='warning')&&(item.answerCnt==0 && item.status!=4))
  endList.value = _.filter(newList, item=>(item.dateStatus=='end')&&(item.answerCnt==0 && item.status!=4))
  completeList.value = _.filter(newList, item=>(item.dateStatus=='end')&&(item.answerCnt>0 || item.status==4))
}

/*
리스트 클래스(li에 기입):
sort01 안전관찰
sort02 안전리더십
sort03 시정조치
--
state01 진행전
state02 임박
state03 종료
state04 완료
*/
const getClass = (item:any):string=>{
  let returnClass = '';
  //타입체크
  if(item.type == 'b')
    returnClass += ' sort01'
  else if(item.type == 'l')
    returnClass += ' sort02'
  else if(item.type == 't')
    returnClass += ' sort03'
  //상태
  if(item.type == 'b'){
    if(item.answerCnt > 0)
      returnClass += ' state04'
    else if(item.answerCnt == 0 && item.dateStatus == 'ready')
      returnClass += ' state01'
    else if(item.answerCnt == 0 && item.dateStatus == 'warning')
      returnClass += ' state02'
    else if(item.answerCnt == 0 && item.dateStatus == 'end')
      returnClass += ' state03'
  }
  else if(item.type == 'l'){

  }
  else if(item.type == 't'){
    if(item.status == '4')
      returnClass += ' state04'
    else if(item.status != '4' && item.dateStatus == 'ready')
      returnClass += ' state01'
    else if(item.status != '4' && item.dateStatus == 'warning')
      returnClass += ' state02'
    else if(item.status != '4' && item.dateStatus == 'end')
      returnClass += ' state03'
  }

  return returnClass
}

//상태문자
const getStatus = (item:any):string=>{
  let returnClass = '';
  //상태
  if(item.type == 'b'){
    if(item.answerCnt > 0)
      returnClass += '완료'
    else if(item.answerCnt == 0 && item.dateStatus == 'ready')
      returnClass += '준비중'
    else if(item.answerCnt == 0 && item.dateStatus == 'warning')
      returnClass += '임박'
    else if(item.answerCnt == 0 && item.dateStatus == 'end')
      returnClass += '만료'
  }
  else if(item.type == 'l'){

  }
  else if(item.type == 't'){
    if(item.status == '4')
      returnClass += '완료'
    else if(item.status != '4' && item.dateStatus == 'ready')
      returnClass += '준비중'
    else if(item.status != '4' && item.dateStatus == 'warning')
      returnClass += '임박'
    else if(item.status != '4' && item.dateStatus == 'end')
      returnClass += '만료'
  }

  return returnClass
}

//아이템이동
const onClickItem = (item:any):void=>{
  if(item.type == 'b')
    utils.movePage('/monitoring/1/'+item.idx)
  else if(item.type == 't')
    utils.movePage('/myBoardDetail/'+item.idx)
}

const toDate = (date:string): Date=>{
  const t = new Date(date)
  return new Date(t.getFullYear(), t.getMonth(), t.getDate())
}
</script>


<style lang="scss">

</style>
