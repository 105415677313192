<template>
  <div class="content-wrap am-page-wrap" :class="amPage">
    <div class="navi-area">
      <div class="m-navi-top">
        <c-btn01 :btnText="'모니터링 대시보드'" @click="amListOpen"/>
      </div>
      <NaviBox @closeMobileMenu="amListOpen"/>
    </div>
    <div class="page1">
      <div class="page1-inner1">
        <div class="tit-group02">
          <h2 class="tit">Quick KPI</h2>
          <div class="navi-btn">
            <v-btn @click="amListOpen"/>
          </div>
        </div>
        <div class="card-group02">
        <div class="card-col ty01">
          <div class="icon ico1" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ periodAnswerCnt }}</div>
            <div class="txt2">참여빈도1</div>
          </div>
          <div class="line"></div>
          <div class="txt-group">
            <div class="txt1">{{ allAnswerCnt }}</div>
            <div class="txt2">참여빈도2</div>
          </div>
        </div>
        <div class="card-col ty01">
          <div class="icon ico1" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ periodAnswerPercent }}<span>%</span></div>
            <div class="txt2">빈도달성율</div>
          </div>
          <div class="line"></div>
          <div class="txt-group">
            <div class="txt1">{{ allAnswerPercent }}<span>%</span></div>
            <div class="txt2">참여달성율</div>
          </div>
        </div>
        <div class="card-col ty02">
          <div class="icon ico2" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ timelineTy1Cnt }}</div>
            <div class="txt2">시정조치횟수</div>
          </div>
        </div>
        <div class="card-col ty02">
          <div class="icon ico2" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ timelineTy1Percent }}<span>%</span></div>
            <div class="txt2">시정조치율</div>
          </div>
        </div>
      </div>
      </div>
      <div class="page1-inner2">
        <div class="tit-group02">
          <h2 class="tit">타임라인</h2>
        </div>
        <div class="content-box">
          <div class="sort-group">
            <div class="sort-row ty01">
              <div class="wl">
                <div class="txt01">기간</div>
                <div class="date-sort">
                  <c-InputDate v-model="startDate" :enableTimePicker="false" readonly="" />
                  <div class="txt02">~</div>
                  <c-InputDate v-model="endDate" :enableTimePicker="false" readonly=""/>
                </div>
                <div class="date-sort">
                  <c-btn02 :btnText="'당일'" class="ty01" @click="onClickDate('today')"/>
                  <c-btn02 :btnText="'분기'" class="ty01" @click="onClickDate('week')"/>
                  <c-btn02 :btnText="'올해'" class="ty01" @click="onClickDate('month')"/>
                </div>
                <Select v-bind="typeSelect" v-model="typeModel"/>
              </div>
              <div class="wr">
                <c-btnText01 :btnText="'전체보기'" class="ty01" @click="utils.movePage('/actionManagementAll')"/>
              </div>
            </div>
          </div>
          <div class="slider-group">
            <v-slide-group
              v-model="model"
              center-active
              show-arrows
              delimiters
            >
              <v-slide-group-item
                v-for="(item,i) in items"
                :key="i"
                v-slot="{ isSelected, toggle }"
              >
                <v-card
                  @click="toggle"
                >
                  <div class="slider-item">
                    <div class="img-slider">
                      <v-carousel
                      >
                        <v-carousel-item
                          v-for="(item2,i2) in item.timelineImages"
                          :key="i2"
                          :src="utils.getBaseUrl('timeline', item2.filePath)"
                          cover
                          @click="onClickPreview(item2)"
                        ></v-carousel-item>
                      </v-carousel>
                    </div>
                    <div @click="onClickDetail(item)">
                      <div class="txt-group">
                        <div class="txt ell01">{{ item.memberName }} / {{ item.deptName }} / {{ item.posName }}</div>
                        <div class="txt2">{{ item.regDateFormat }}</div>
                      </div>
                      <ul class="txt-group2">
                        <li v-if="item.type=='1'">
                          <div class="tit">모니터링행동</div>
                          <div class="txt ell01">{{ item.behaviorTitle }}</div>
                        </li>
                        <li v-if="item.type=='1'">
                          <div class="tit">장소</div>
                          <div class="txt ell01">{{ item.place }}</div>
                        </li>
                        <li>
                          <div class="tit">{{item.type=='1'?'구체적상황':'칭찬 사유'}}</div>
                          <div class="txt ell01">{{ item.situation }}</div>
                        </li>
                        <li v-if="item.type=='1'">
                          <div class="tit">원인</div>
                          <div class="txt ell01">{{ item.cause }}</div>
                        </li>
                        <li v-if="item.type=='1'">
                          <div class="tit">시정조치내용</div>
                          <div class="txt ell01">{{ item.action }}</div>
                        </li>
                        <li>
                          <div class="tit">{{item.type=='1'?'시정조치대상자':'칭찬대상자'}}</div>
                          <div class="txt ell01">{{ item.targetText }}</div>
                        </li>
                        <li v-if="item.actionYn=='Y'">
                          <div class="tit">조치기한</div>
                          <div class="txt ell01">{{ item.goalDate }}</div>
                        </li>
                        <li v-if="item.type=='1'">
                          <div class="tit">진행상태</div>
                          <div class="txt2 c01">{{ item.statusText }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
          </div>
        </div>
      </div>
      <div class="page1-inner3">
        <div class="tit-group02">
          <h2 class="tit">메뉴</h2>
        </div>
        <div class="m-menu-list">
          <v-btn class="n1" @click="amListOpen">모니터링</v-btn>
          <v-btn class="n2" @click="utils.movePage('/actionManagementAll')">타임라인</v-btn>
          <v-btn class="n3" @click="utils.movePage('/totalReport')">보고서</v-btn>
          <v-btn class="n4" @click="utils.movePage('/myBoard')">시정조치</v-btn>
        </div>
      </div>
    </div>
  </div>
  <AmDialog01 v-model="detailPopupModel" @close="detailPopupModel=false"/>
  <DialogImgView v-model="previewDialog" @close="previewDialog=false" :src="previewSrc"/>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TimelineService from "@/services/timeline.service";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const detailPopupModel = ref(false)
const previewDialog = ref(false)
const startDate = ref('')
const endDate = ref('')
const items = ref([])
const amPage = ref('naviOff')
const typeModel = ref({name:'본인', value:'1'})
const periodAnswerCnt = ref(0)
const allAnswerCnt = ref(0)
const periodAnswerPercent = ref(0)
const allAnswerPercent = ref(0)
const timelineTy1Cnt = ref(0)
const timelineTy1Percent = ref(0)
const previewSrc = ref('')
const model = ref(false)

//ui
const typeSelect = {items: [{name:'본인', value:'1'},{name:'부서', value:'2'}]}

//watch
watch(startDate, (newObj) => {
  onClickSearch()
})

watch(endDate, (newObj) => {
  onClickSearch()
})

watch(typeModel, (newObj) => {
  onClickSearch()
})

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: '1',
        monitoringTitle: '모니터링1',
        memberName: '김동임',
        monitoringYn: 'Y',
        timelineYn: 'N',
        regDateFormat: '2024-01-25 13:28:40',
        moData: '생산1팀/과장',
      },
    ]
  }
  else {
    const user = JSON.parse(localStorage.getItem('user'))
    let params = {}
    BbsService.getBbsMonitoringSummary(params).then(
      (response) => {
        periodAnswerCnt.value = response.result.periodAnswerCnt
        allAnswerCnt.value = response.result.allAnswerCnt
        periodAnswerPercent.value = response.result.periodAnswerPercent
        allAnswerPercent.value = response.result.allAnswerPercent
        timelineTy1Cnt.value = response.result.timelineTy1Cnt
        timelineTy1Percent.value = response.result.timelineTy1Percent
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )

    onClickSearch()
  }
})

//function
function amListOpen(){
  if(amPage.value == 'naviOff')
    amPage.value = 'naviOn'
  else
    amPage.value = 'naviOff'
}

function onClickPreview(item){
  previewSrc.value = utils.getBaseUrl('timeline', item.filePath)
  previewDialog.value = true
}

function onClickDetail(item){
  storeRef.popupIdx.value = item.idx
  detailPopupModel.value = true
}

function onClickDate(type){
  const end = dayjs().format('YYYY-MM-DD')
  let start = dayjs().format('YYYY-MM-DD')
  if(type == 'week')
    start = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  else if(type == 'month')
    start = dayjs().subtract(4, 'month').format('YYYY-MM-DD')

  startDate.value = start.toString()
  endDate.value = end.toString()
}

function onClickSearch(){
  const user = JSON.parse(localStorage.getItem('user'))
  let params = {
    startDate:startDate.value,
    endDate:endDate.value,
    filterType:typeModel.value['value'],
  }

  TimelineService.getTimelineList(params).then(
    (response) => {
      //타임라인
      items.value = response.result
      _.forEach(items.value, function(val, key) {
        val.typeText = val.type=='1'?'교정적 안전대화':'지지적 안전대화'
        val.actionYnText = val.actionYn=='Y'?'필요':'불필요'
        val.targetText = '없음'
        if(val.targetType == '2') val.target = val.targetDept
        else if(val.targetType == '3') val.target = val.targetMember
        val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(val.goalDate).format('YYYY-MM-DD')
        val.statusText = '준비'
        if(val.status == '2')
          val.statusText = '진행중'
        else if(val.status == '3')
          val.statusText = '보류'
        else if(val.status == '4')
          val.statusText = '완료'
      })
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}
</script>
<style lang="scss">
</style>
