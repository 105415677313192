<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="60rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">
            상세보기
            <div class="sub-txt02">
              <div class="col">
                안전대화 <span>{{ timelineType1Cnt }}</span>
              </div>
              <div class="line"></div>
              <div class="col">
                칭찬대화 <span>{{ timelineType2Cnt }}</span>
              </div>
            </div>
          </div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>
        <!--content-->
        <v-card-text>
          <div class="slider-group">
            <v-slide-group
              v-model="model"
              center-active
              show-arrows
              delimiters
            >
              <v-slide-group-item
                v-for="(item,i) in items"
                :key="i"
                v-slot="{ isSelected, toggle }"
              >
                <v-card
                  @click="toggle"
                >
                  <div class="slider-item">
                    <div class="img-slider">
                      <v-carousel
                      >
                        <v-carousel-item
                          v-for="(item2,i2) in item.timelineImages"
                          :key="i2"
                          :src="utils.getBaseUrl('timeline', item2.filePath)"
                          cover
                          @click="onClickPreview(item2)"
                        ></v-carousel-item>
                      </v-carousel>
                    </div>
                    <div>
                      <div class="txt-group">
                        <div class="txt ell01">{{ item.memberName }} / {{ item.deptName }} / {{ item.posName }}</div>
                        <div class="txt2">{{ item.regDateFormat }}</div>
                      </div>
                      <ul class="txt-group2">
                        <li v-if="item.type=='1'">
                          <div class="tit">모니터링행동</div>
                          <div class="txt ell01">{{ item.behaviorTitle }}</div>
                        </li>
                        <li v-if="item.type=='1'">
                          <div class="tit">장소</div>
                          <div class="txt ell01">{{ item.place }}</div>
                        </li>
                        <li>
                          <div class="tit">{{item.type=='1'?'구체적상황':'칭찬 사유'}}</div>
                          <div class="txt ell01">{{ item.situation }}</div>
                        </li>
                        <li v-if="item.type=='1'">
                          <div class="tit">원인</div>
                          <div class="txt ell01">{{ item.cause }}</div>
                        </li>
                        <li v-if="item.type=='1'">
                          <div class="tit">시정조치내용</div>
                          <div class="txt ell01">{{ item.action }}</div>
                        </li>
                        <li>
                          <div class="tit">{{item.type=='1'?'시정조치대상자':'칭찬대상자'}}</div>
                          <div class="txt ell01">{{ item.targetText }}</div>
                        </li>
                        <li v-if="item.actionYn=='Y'">
                          <div class="tit">조치기한</div>
                          <div class="txt ell01">{{ item.goalDate }}</div>
                        </li>
                        <li v-if="item.type=='1'">
                          <div class="tit">진행상태</div>
                          <div class="txt2 c01">{{ item.statusText }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 :btnText="'확인'" @click="dialog=false"/>
        </v-card-actions>
      </v-card>
      <AmDialog01 v-model="detailPopupModel" @close="detailPopupModel=false"/>
      <DialogImgView v-model="previewDialog" @close="previewDialog=false" :src="previewSrc"/>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";
import BbsService from "@/services/bbs.service";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const dialog = defineModel()

//bind
const items = ref([])

//ui
const detailPopupModel = ref(false)
const previewDialog = ref(false)
const previewSrc = ref('')
const model = ref(false)
const timelineType1Cnt = ref(0)
const timelineType2Cnt = ref(0)

//hook
onUpdated(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        "idx": 21,
        "companyIdx": 3,
        "monitoringIdx": 6,
        "answerIdx": 83,
        "memberIdx": 34,
        "deptIdx": 169,
        "posIdx": 165,
        "shiftYn": "N",
        "type": "1",
        "actionYn": "N",
        "targetType": "1",
        "targetDeptIdx": 0,
        "targetMemberIdx": 0,
        "goalDate": null,
        "place": "4",
        "situation": "222222",
        "cause": "22222",
        "action": "333333",
        "status": "1",
        "delayReason": null,
        "modIdx": 34,
        "regIdx": 0,
        "modDate": "2025-01-19 21:39:27.789065",
        "modDateFormat": "2025.01.19",
        "regDate": "2025-01-19 21:39:13.553219",
        "regDateFormat": "2025.01.19",
        "tempSeq": 2,
        "isTemp": "N",
        "timelineImages": [
          {
            "idx": 28,
            "timelineIdx": 21,
            "type": "t",
            "filePath": "3b893c66-c695-49d2-951e-69e1ab612486.png",
            "fileName": "2.png",
            "memberIdx": 34,
            "monitoringIdx": 6,
            "isTemp": "N",
            "tempSeq": 2,
            "modDate": "2025-01-19 21:39:27.0",
            "regDate": "2025-01-19 21:39:24.0",
            "file": null,
            "files": null
          }
        ],
        "timelineComments": null,
        "timelineLogs": null,
        "memberName": "관리자",
        "deptName": "부서2",
        "targetMemberName": null,
        "targetDeptName": null,
        "posName": "뭉뭉이",
        "behaviorTitle": "2",
        "behaviorIdx": 2,
        "changeType": null
      },
    ]
  }
  else {
    if(dialog.value)
      onClickSearch()
  }
})

function onClickSearch(){
  let params:any = storeRef.popupParams.value
  params.idx = null

  TimelineService.getTimelineList(params).then(
    (response) => {
      //타임라인
      items.value = response.result
      _.forEach(items.value, function(val, key) {
        val.typeText = val.type=='1'?'교정적 안전대화':'지지적 안전대화'
        val.actionYnText = val.actionYn=='Y'?'필요':'불필요'
        val.targetText = '없음'
        if(val.targetType == '2') val.target = val.targetDept
        else if(val.targetType == '3') val.target = val.targetMember
        val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(val.goalDate).format('YYYY-MM-DD')
        val.statusText = '준비'
        if(val.status == '2')
          val.statusText = '진행중'
        else if(val.status == '3')
          val.statusText = '보류'
        else if(val.status == '4')
          val.statusText = '완료'
      })

      timelineType1Cnt.value = _.filter(items.value, {type:'1'}).length
      timelineType2Cnt.value = _.filter(items.value, {type:'2'}).length
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickPreview(item){
  previewSrc.value = utils.getBaseUrl('timeline', item.filePath)
  previewDialog.value = true
}
</script>


<style lang="scss">
</style>
