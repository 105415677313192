import api from "./api";
import Utils from "@/common/Utils";

class CalendarService {
  getCalendarList(params) {
    return api
      .post("/calendar/getCalendarList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new CalendarService();
