import utils from "@/common/Utils";

class NativeBridge {
  constructor() {
    this.registerCallback();
  }

  public init(){
    utils.log('NativeBridge init')
  }

  private registerCallback() {
    window.insertPushTokenResult = this.insertPushTokenResult;
  }

  private insertPushTokenResult(message: any) {
    alert("네이티브에서 전달된 메시지:" + message.memberIdx )
  }
}

// 싱글톤 패턴으로 인스턴스 생성
export default new NativeBridge()
