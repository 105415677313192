<template>
	<div class="cv-header">
		<div class="cv-header-nav">
      <!--
			<button :disabled="!headerProps.previousYear" class="previousYear" aria-label="Previous Year" @click.prevent="onInput(headerProps.previousYear!)">
				{{ previousYearLabel }}
			</button>
      -->
			<button
				:disabled="!headerProps.previousPeriod"
				class="previousPeriod"
				aria-label="Previous Period"
				@click.prevent="onInput(headerProps.previousPeriod!)"
				v-html="previousPeriodLabel"
			/>

      <div class="periodLabel">
        <slot name="label">{{ headerProps.periodLabel }}</slot>
      </div>
			<button :disabled="!headerProps.nextPeriod" class="nextPeriod" aria-label="Next Period" @click.prevent="onInput(headerProps.nextPeriod!)">
				{{ nextPeriodLabel }}
			</button>
      <!--
			<button :disabled="!headerProps.nextYear" class="nextYear" aria-label="Next Year" @click.prevent="onInput(headerProps.nextYear!)">
				{{ nextYearLabel }}
			</button>
			-->

      <button class="currentPeriod" aria-label="Current Period" @click.prevent="onInput(headerProps.currentPeriod)">
        {{ headerProps.currentPeriodLabel }}
      </button>
		</div>

    <div class="selected-group3">
      <v-select
        v-model="calenderModel"
        :items="CalenderItems"
      ></v-select>
    </div>
	</div>
</template>
<script setup lang="ts">
import {ref, watch} from 'vue';

const CalenderItems = ['주별', '월별']
const calenderModel = ref('월별')

import { IHeaderProps } from "./IHeaderProps"
import utils from "@/common/Utils";

defineProps({
	headerProps: {
		type: Object as () => IHeaderProps,
		required: true,
	},
	previousYearLabel: { type: String, default: "<<" },
	previousPeriodLabel: { type: String, default: "<" },
	nextPeriodLabel: { type: String, default: ">" },
	nextYearLabel: { type: String, default: ">>" },
})

const emit = defineEmits<{
	(e: "input", day: Date): void
}>()

const onInput = (d: Date): void => emit("input", d)

//watch
watch(calenderModel, (newDate) => {
  utils.movePage('/safetyCalender')
});
</script>
<style>
.cv-header {
	display: inline-flex;
	flex: 0 1 auto;
	flex-flow: row nowrap;
	align-items: center;
	min-height: 2.5em;
	border:0;
}

.cv-header .periodLabel {
	display:inline-flex;
	flex: 1 1 auto;
	flex-flow: row nowrap;
	min-height: 1.5em;
	line-height: 1;
	font-size: 1.5em;
}

.cv-header,
.cv-header button {
	border-style: solid;
	border-color: #ddd;
}

.cv-header-nav,
.cv-header .periodLabel {
	margin: 0.1em 0.6em;
}

.cv-header-nav button,
.cv-header .periodLabel {
	padding: 0.4em 0.6em;
}

.cv-header button {
	box-sizing: border-box;
	line-height: 1em;
	font-size: 1em;
	border-width: 1px;
}
</style>
