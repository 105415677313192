import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false, // Composition API와 함께 사용하려면 false로 설정
  locale: 'A', // 기본 언어
  fallbackLocale: 'B', // 대체 언어
  messages: {}
})

export default i18n
