<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="tit-group ty01" v-if="false">
        <h4 class="tit">{{monitoringInfo.monitoringTitle}}</h4>
        <c-btnLine01 :btnText="'보고서 유효성 검사'" class="sm ty03" @click="reportValidationDialogModel=true"/>
      </div>
      <div class="tit-group02 inner-ty">
        <h2 class="tit"></h2>
        <div class="filter-btn-group">
          <v-btn class="reset" :class="filterDiv1">필터초기화</v-btn>
          <v-btn class="filter" @click="filterOpen1" :class="filterDiv1">필터</v-btn>
        </div>
      </div>
      <div class="sort-group ty01" :class="filterDiv1">
        <div class="sort-row">
          <div class="wl">
            <div class="date-sort">
              <c-InputDate v-model="startDate" :enableTimePicker="false" readonly="" />
              <div class="txt02">~</div>
              <c-InputDate v-model="endDate" :enableTimePicker="false" readonly=""/>
            </div>
            <div class="date-sort">
              <c-btn02 :btnText="'당월'" class="ty01" @click="onClickDate('month')"/>
              <c-btn02 :btnText="'분기'" class="ty01" @click="onClickDate('quarter')"/>
              <c-btn02 :btnText="'올해'" class="ty01" @click="onClickDate('year')"/>
            </div>
          </div>
        </div>
        <div class="sort-row">
          <div class="wl">
            <Select v-bind="memberSelect" v-model="memberModel"/>
            <Select v-bind="deptSelect" v-model="deptModel"/>
            <Select v-bind="posSelect" v-model="posModel"/>
            <Select v-bind="shiftSelect" v-model="shiftModel"/>
            <Select v-bind="monitoringSelect" v-model="monitoringModel"/>
          </div>
        </div>
      </div>
    </div>
    <!--row-->
    <div class="tit-group02 ty02 mo-ty02">
      <h2 class="tit">간단 리포트</h2>
    </div>
    <div class="content-inner-box ty01">
      <div class="tit-group ty01">
        <h4 class="tit">Quick KPI</h4>
      </div>
      <div class="card-group02 ty02">
        <!--card-item-->
        <div class="card-col-wrap" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryFrequency=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryFrequency"
                        @click.stop="onClickUpdateDisplay('summaryFrequency',reportDisplayInfo.summaryFrequency)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ periodAnswerCnt }}</div>
              </div>
              <div class="txt2">참여빈도1</div>
            </div>
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ allAnswerCnt }}</div>
              </div>
              <div class="txt2">참여빈도2</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryRate=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryRate"
                        @click.stop="onClickUpdateDisplay('summaryRate',reportDisplayInfo.summaryRate)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ periodAnswerPercent }}<span>%</span></div>
              </div>
              <div class="txt2">빈도달성율</div>
            </div>
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ allAnswerPercent }}<span>%</span></div>
              </div>
              <div class="txt2">참여달성율</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryTimelineRate=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryTimelineRate"
                        @click.stop="onClickUpdateDisplay('summaryTimelineRate',reportDisplayInfo.summaryTimelineRate)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ timelineTy1Cnt }}</div>
              </div>
              <div class="txt2">시정조치횟수</div>
            </div>
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ timelineTy1Percent }}<span>%</span></div>
              </div>
              <div class="txt2">시정조치율</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-group02 ty02 c-ty01">
        <!--card-item-->
        <div class="card-col-wrap ty01" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryTimelineAll=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryTimelineAll"
                        @click.stop="onClickUpdateDisplay('summaryTimelineAll',reportDisplayInfo.summaryTimelineAll)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ timelineAllRate }}<span>%</span></div>
              </div>
              <div class="txt2">안전대화 참여율</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap ty01" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryTimelineType1=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryTimelineType1"
                        @click.stop="onClickUpdateDisplay('summaryTimelineType1',reportDisplayInfo.summaryTimelineType1)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ timelineTy1Rate }}<span>%</span></div>
              </div>
              <div class="txt2">교정적 피드백 실시율</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap ty01" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryTimelineType2=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryTimelineType2"
                        @click.stop="onClickUpdateDisplay('summaryTimelineType2',reportDisplayInfo.summaryTimelineType2)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ timelineTy2Rate }}<span>%</span></div>
              </div>
              <div class="txt2">지지적 피드백 실시율</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap ty02" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryTimelineReceive=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryTimelineReceive"
                        @click.stop="onClickUpdateDisplay('summaryTimelineReceive',reportDisplayInfo.summaryTimelineReceive)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ myTimelineTy1Cnt }}</div>
              </div>
              <div class="txt2">내가 받은 교정적 피드백</div>
            </div>
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">{{ myTimelineTy2Cnt }}</div>
              </div>
              <div class="txt2">내가 받은 지지적 피드백</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-group02 ty03">
        <div class="card-col" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryMemberTop=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryMemberTop"
                        @click.stop="onClickUpdateDisplay('summaryMemberTop',reportDisplayInfo.summaryMemberTop)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="tit">
            당월 활동 우수자
            <c-tooltip :TooltipText="'당월 활동 우수자란?'"/>

          </div>
          <v-carousel
            hide-delimiters
            height="auto"
            :continuous="false"
          >
            <v-carousel-item
              v-for="(item, i) in memberAnswerList"
              :key="'memberAnswerList_'+item.idx"
            >
              <div class="txt1">{{ item.memberName }} / {{ item.deptName }} / {{ item.posName }}</div>
              <div class="txt2">
                <span>모니터링 <span>{{ item.allAnswerCnt }}회</span></span>
                <span>안전대화 <span>{{ item.timelineTy1Cnt + item.timelineTy2Cnt }}회</span></span>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
        <div class="card-col" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.summaryMemberBottom=='Y')">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.summaryMemberBottom"
                        @click.stop="onClickUpdateDisplay('summaryMemberBottom',reportDisplayInfo.summaryMemberBottom)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <div class="tit">
            당월 활동 저조자
            <c-tooltip :TooltipText="'당월 활동 저조자란?'"/>

          </div>
          <v-carousel
            hide-delimiters
            height="auto"
            :continuous="false"
          >
            <v-carousel-item
              v-for="(item, i) in memberAnswerListReverse"
              :key="'memberAnswerListReverse_'+item.idx"
            >
              <div class="txt1">{{ item.memberName }} / {{ item.deptName }} / {{ item.posName }}</div>
              <div class="txt2">
                <span>모니터링 <span>{{ item.allAnswerCnt }}회</span></span>
                <span>안전대화 <span>{{ item.timelineTy1Cnt + item.timelineTy2Cnt }}회</span></span>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </div>
    <!--row-->
    <div class="content-inner-box ty01" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.lineFrequencyRate=='Y')">
      <div class="tit-group ty01">
        <h4 class="tit">참여율</h4>
        <div class="wr">
          <div class="check-group ty02 mo-none">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.lineFrequencyRate"
                        @click.stop="onClickUpdateDisplay('lineFrequencyRate',reportDisplayInfo.lineFrequencyRate)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <Select v-bind="periodSelect" v-model="frequencyRatePeriodModel"/>
        </div>
      </div>
      <!--그래프 위치-->
      <div class="graph-group">
        <Line :data="frequencyRateLineData" :options="options_line" />
      </div>
    </div>
    <!--row-->
    <div class="content-inner-box ty01">
      <div class="tit-group ty01" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.lineBehaviorRate=='Y')">
        <h4 class="tit">안전행동률</h4>
        <div class="wr">
          <div class="check-group ty02 mo-none">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.lineBehaviorRate"
                        @click.stop="onClickUpdateDisplay('lineBehaviorRate',reportDisplayInfo.lineBehaviorRate)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <Select v-bind="periodSelect" v-model="behaviorRatePeriodModel"/>
        </div>
      </div>
      <!--그래프 위치-->
      <div class="graph-group" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.lineBehaviorRate=='Y')">
        <Line :data="behaviorRateLineData" :options="options_line" />
      </div>
      <div class="cont-row" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.barBehaviorRate=='Y')">
        <div class="tit-group ty01">
          <h4 class="tit02">안전행동률 - 카테고리별 추이</h4>
          <div class="wr">
            <div class="check-group ty02 mo-none">
              <v-checkbox label="노출여부"
                          v-if="user.roles != 'USER'"
                          :model-value="reportDisplayInfo.barBehaviorRate"
                          @click.stop="onClickUpdateDisplay('barBehaviorRate',reportDisplayInfo.barBehaviorRate)"
                          true-value="Y" false-value="N"></v-checkbox>
            </div>
          </div>
        </div>
        <!--그래프 위치-->
        <div class="graph-group">
          <div class="graph-txt">
            <div class="txt"
                 v-for="(item, i) in behaviorStep1BarData.labelsObj"
                 :key="i">
              <button @click="apiReportBehaviorRate('behavior', item.categoryIdx, '')">{{item.label}}</button>
            </div>
          </div>
          <div class="graph-cont">
            <Bar :data="behaviorStep1BarData" :options="options_bar1" ref="behaviorStep1Bar"/>
          </div>
        </div>
      </div>
      <div class="cont-row" v-if="behaviorStep2BarData.labelsObj">
        <div class="tit-group ty01">
          <h4 class="tit02">
            안전행동률 - 관찰항목별 추이
            <span class="sub-txt">
              관찰항목을 클릭하면, 세부 관찰 항목에 대한 결과를 확인하실 수 있습니다.
            </span>
          </h4>
          <div class="wr">
            <div class="check-group ty02 mo-none">
              <v-checkbox label="노출여부"></v-checkbox>
            </div>
          </div>
        </div>
        <!--그래프 위치-->
        <div class="graph-group">
          <div class="graph-txt">
            <div class="txt"
                 v-for="(item, i) in behaviorStep2BarData.labelsObj"
                 :key="i">
              <button @click="apiReportBehaviorRate('comment', item.categoryIdx, item.behaviorIdx)">{{item.label}}</button>
            </div>
          </div>
          <div class="graph-cont">
            <Bar :data="behaviorStep2BarData" :options="options_bar2" ref="behaviorStep2Bar"/>
          </div>
        </div>
      </div>
      <div class="cont-row" v-if="behaviorStep3BarData.labelsObj">
        <div class="tit-group ty01">
          <h4 class="tit02">
            안전행동률 - 세부 관찰항목별 추이
          </h4>
          <div class="wr">
            <div class="check-group ty02 mo-none">
              <v-checkbox label="노출여부"></v-checkbox>
            </div>
          </div>
        </div>
        <!--그래프 위치-->
        <div class="graph-group">
          <div class="graph-txt">
            <div class="txt"
                 v-for="(item, i) in behaviorStep3BarData.labelsObj"
                 :key="i">
              <button>{{item.label}}</button>
            </div>
          </div>
          <div class="graph-cont">
            <Bar :data="behaviorStep3BarData" :options="options_bar" />
          </div>
        </div>
      </div>
    </div>
    <!--row-->
    <div class="content-inner-box ty01">
      <div class="tit-group ty01" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.lineTimelineRate=='Y')">
        <h4 class="tit">안전대화 실시율
          <c-tooltip :TooltipText="'모든 결과값이 100%로 표기되는 이유는 크게 두 가지로, ① 모니터링 설정 상 안전대화를 무조건 실시해야 하는 경우 ② 강제 설정은 되어 있지 않지만, 실제로 모든 참석자들이 안전대화를 실시한 경우 입니다.'"/>
        </h4>
        <div class="wr">
          <div class="check-group ty02 mo-none">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.lineTimelineRate"
                        @click.stop="onClickUpdateDisplay('lineTimelineRate',reportDisplayInfo.lineTimelineRate)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <Select v-bind="periodSelect" v-model="timelineRatePeriodModel"/>
        </div>
      </div>
      <!--그래프 위치-->
      <div class="graph-group" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.lineTimelineRate=='Y')">
        <Line :data="timelineRateLineData" :options="options_line" />
      </div>
      <div class="cont-row mo-none" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.listTimelineBehavior=='Y')">
        <div class="tit-group ty01">
          <h4 class="tit">안전대화 활동상세</h4>
          <div class="wr">
            <div class="check-group ty02">
              <v-checkbox label="노출여부"
                          v-if="user.roles != 'USER'"
                          :model-value="reportDisplayInfo.listTimelineBehavior"
                          @click.stop="onClickUpdateDisplay('listTimelineBehavior',reportDisplayInfo.listTimelineBehavior)"
                          true-value="Y" false-value="N"></v-checkbox>
            </div>
            <c-btnLine01 :btnText="'다운로드'"/>
          </div>
        </div>
        <!--그래프 위치-->
        <div class="tbl-group size-custom14">
          <v-data-table
            :headers="headers"
            :items="timelineBehaviorItems"
            hide-default-footer
            return-object
          >
            <template v-slot:item.action="{ item }" >
              <div class="ell01">
                {{item.action}}
              </div>
            </template>
            <template v-slot:item.detailView="{ item }">
              <div class="flex-c">
                <BtnIcon class="btn-ico-search" @click="onClickTimelineBehaviorDialog(item.behaviorIdx)"/>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <!--row-->
    <div class="content-inner-box ty01 mo-none" v-if="user.roles != 'USER' || (user.roles == 'USER' && reportDisplayInfo.listMemberActivity=='Y')">
      <div class="tit-group ty01">
        <h4 class="tit">개인별 활동상세
          <div class="sub-txt02">
            <div class="col">
              총 참여횟수 <span>{{behaviorStep3Cnt}}회</span>
            </div>
            <div class="line"></div>
            <div class="col">
              총 참여율 <span>{{ behaviorStep3Rate }}%</span>
            </div>
          </div>
        </h4>
        <div class="wr">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"
                        v-if="user.roles != 'USER'"
                        :model-value="reportDisplayInfo.listMemberActivity"
                        @click.stop="onClickUpdateDisplay('listMemberActivity',reportDisplayInfo.listMemberActivity)"
                        true-value="Y" false-value="N"></v-checkbox>
          </div>
          <c-btnLine01 :btnText="'다운로드'"/>
        </div>
      </div>
      <!--그래프 위치-->
      <div class="tbl-group size-custom15">
        <v-data-table
          :headers="headers2"
          :items="memberActivityItems"
          hide-default-footer
          return-object
        >
          <template v-slot:item.author="{ item }" >
            <div class="ell01">
              {{item.author}}
            </div>
          </template>
          <template v-slot:item.detailView="{ item }">
            <div class="flex-c">
              <BtnIcon class="btn-ico-search" @click="memberActivityDialogModel=true"/>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
  <MtDialog01 v-model="timelineBehaviorDialogModel" @close="timelineBehaviorDialogModel=false"/>
  <MtDialog02 v-model="memberActivityDialogModel" @close="memberActivityDialogModel=false"/>
  <MtDialog03 v-model="reportValidationDialogModel" @close="reportValidationDialogModel=false" @confirm="confirmValidation"/>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import BbsService from "@/services/bbs.service";
import CompanyService from "@/services/company.service";
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js'
import { Line, Bar } from 'vue-chartjs'
import TimelineService from "@/services/timeline.service";

//plugin
dayjs.extend(quarterOfYear)
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//bind
const snackbar = ref(false)
const snackText = ref('')
let viewIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value
const timelineBehaviorDialogModel = ref(false)
const memberActivityDialogModel = ref(false)
const reportValidationDialogModel = ref(false)
const startDate = ref('')
const endDate = ref('')
const memberModel = ref({name:'개인', value:''})
const deptModel = ref({name:'부서', value:''})
const posModel = ref({name:'직책', value:''})
const shiftModel = ref({name: '교대조', value: ''})
const monitoringModel = ref({name:'모니터링(전체)', value:''})
const monitoringInfo:any = ref({})
const reportDisplayInfo:any = ref({})
const periodAnswerCnt = ref(0)
const allAnswerCnt = ref(0)
const periodAnswerPercent = ref(0)
const allAnswerPercent = ref(0)
const timelineTy1Cnt = ref(0)
const timelineTy1Percent = ref(0)
const timelineAllRate = ref(0)
const timelineTy1Rate = ref(0)
const timelineTy2Rate = ref(0)
const myTimelineTy1Cnt = ref(0)
const myTimelineTy2Cnt = ref(0)
const memberAnswerList = ref([])
const memberAnswerListReverse = ref([])
const frequencyRatePeriodModel = ref({name:'주', value:'week'})
const behaviorRatePeriodModel = ref({name:'주', value:'week'})
const timelineRatePeriodModel = ref({name:'주', value:'week'})
const timelineBehaviorItems:any = ref([])
const memberActivityItems:any = ref([])
const frequencyRateLineData:any = ref({labels: [], datasets: []})
const behaviorRateLineData:any = ref({labels: [], datasets: []})
const timelineRateLineData:any = ref({labels: [], datasets: []})
const behaviorStep1BarData:any = ref({labels: [], datasets: []})
const behaviorStep1Bar:any = ref<HTMLInputElement | null>(null)
const behaviorStep2BarData:any = ref({labels: [], datasets: []})
const behaviorStep2Bar:any = ref<HTMLInputElement | null>(null)
const behaviorStep3BarData:any = ref({labels: [], datasets: []})
const behaviorStep3Cnt = ref(0)
const behaviorStep3Rate = ref(0)
let firstLoadingDone = false
let searchParams:any = {}
const user = ref(JSON.parse(localStorage.getItem('user')))

//ui
const memberSelect = ref({items: [{name:'개인', value:''}]})
const deptSelect = ref({items: [{name:'부서', value:''}]})
const posSelect = ref({items: [{name:'직책', value:''}]})
const shiftSelect = ref({items: [{name: '교대조', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]})
const monitoringSelect = ref({items: [{name:'모니터링(전체)', value:''}]})
const periodSelect = ref({items: [{name:'주', value:'week'},{name:'월', value:'month'}]})

//ui
const headers = ref([

  { title: '행동', key: 'action' },
  { title: '안전대화', key: 'safetyTalk' },
  { title: '칭찬대화', key: 'cheerTalk' },
  { title: '상세내용', key: 'detailView' },
])
const headers2 = ref([

  { title: '작성자', key: 'author' },
  { title: '모니터링', key: 'monitoring' },
  { title: '안전대화', key: 'safetyTalk' },
  { title: '최근제출일', key: 'submit' },
  { title: '상세내용', key: 'detailView' },
])
const options_line:any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      title: {
        display: true,
        text: ''
      }
    },
    y: {
      title: {
        display: true,
        text: ''
      },
      min: 0,
      max: 100,
      ticks: {
        // forces step size to be 50 units
        stepSize: 20
      },
      display:false
    },
  },
  plugins: {
    legend: {
      usePointStyle: true,
      position: 'bottom'
    },
  }
}
const options_bar:any = {
  indexAxis: 'y',
  barThickness:20,
  responsive: true,
  maintainAspectRatio: false,//뷰포트가 달라지면 원래의 비율을 유지한 채 늘어난다.반응형인 경우 아래 옵션을 추가하면 비율이 아닌 부모의 크기에 맞춰 늘어나고 줄어든다.
  scales: {
    x: {
      min: 0,
      max: 100,
      ticks: {
        // forces step size to be 50 units
        stepSize: 20
      }
    },
    y:{
      display:false
    }
  },
  plugins: {
    legend: {
      display:false,
      usePointStyle: true,
      position: 'bottom'
    },
  }
}
const options_bar1:any = {
  indexAxis: 'y',
  barThickness:20,
  responsive: true,
  maintainAspectRatio: false,//뷰포트가 달라지면 원래의 비율을 유지한 채 늘어난다.반응형인 경우 아래 옵션을 추가하면 비율이 아닌 부모의 크기에 맞춰 늘어나고 줄어든다.
  scales: {
    x: {
      min: 0,
      max: 100,
      ticks: {
        // forces step size to be 50 units
        stepSize: 20
      }
    },
    y:{
      display:false
    }
  },
  plugins: {
    legend: {
      display:false,
      usePointStyle: true,
      position: 'bottom'
    },
  },
  onClick: (e) => {
    const chart = behaviorStep1Bar.value.chart;
    const event = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false)
    const categoryIdx = behaviorStep1BarData.value.labelsObj.at(event.at(0).index).categoryIdx
    apiReportBehaviorRate('behavior', categoryIdx, '')
  }
}
const options_bar2:any = {
  indexAxis: 'y',
  barThickness:20,
  responsive: true,
  maintainAspectRatio: false,//뷰포트가 달라지면 원래의 비율을 유지한 채 늘어난다.반응형인 경우 아래 옵션을 추가하면 비율이 아닌 부모의 크기에 맞춰 늘어나고 줄어든다.
  scales: {
    x: {
      min: 0,
      max: 100,
      ticks: {
        // forces step size to be 50 units
        stepSize: 20
      }
    },
    y:{
      display:false
    }
  },
  plugins: {
    legend: {
      display:false,
      usePointStyle: true,
      position: 'bottom'
    },
  },
  onClick: (e) => {
    const chart = behaviorStep2Bar.value.chart;
    const event = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false)
    const categoryIdx = behaviorStep2BarData.value.labelsObj.at(event.at(0).index).categoryIdx
    const behaviorIdx = behaviorStep2BarData.value.labelsObj.at(event.at(0).index).behaviorIdx
    apiReportBehaviorRate('comment', categoryIdx, behaviorIdx)
  }
}

//watch
watch(startDate, (newObj) => {
  if(firstLoadingDone) {
    apiReportSummary()
    apiReportRate('')
    apiReportBehaviorRate('category','','')
    apiTimelineBehaviorList()
    apiTimelineMemberList()
  }
})
watch(endDate, (newObj) => {
  if(firstLoadingDone){
    apiReportSummary()
    apiReportRate('')
    apiReportBehaviorRate('category','','')
    apiTimelineBehaviorList()
    apiTimelineMemberList()
  }
})
watch(deptModel, (newObj) => {
  if(firstLoadingDone){
    apiReportSummary()
    apiReportRate('')
    apiReportBehaviorRate('category','','')
    apiTimelineBehaviorList()
    apiTimelineMemberList()
  }
})
watch(posModel, (newObj) => {
  if(firstLoadingDone){
    apiReportSummary()
    apiReportRate('')
    apiReportBehaviorRate('category','','')
    apiTimelineBehaviorList()
    apiTimelineMemberList()
  }
})
watch(memberModel, (newObj) => {
  if(firstLoadingDone){
    apiReportSummary()
    apiReportRate('')
    apiReportBehaviorRate('category','','')
    apiTimelineBehaviorList()
    apiTimelineMemberList()
  }
})
watch(shiftModel, (newObj) => {
  if(firstLoadingDone){
    apiReportSummary()
    apiReportRate('')
    apiReportBehaviorRate('category','','')
    apiTimelineBehaviorList()
    apiTimelineMemberList()
  }
})
watch(monitoringModel, (newObj) => {
  if(firstLoadingDone){
    apiReportSummary()
    apiReportRate('')
    apiReportBehaviorRate('category','','')
    apiTimelineBehaviorList()
    apiTimelineMemberList()
  }
})
watch(frequencyRatePeriodModel, (newObj) => {
  if(firstLoadingDone)
    apiReportRate('frequency')
})
watch(behaviorRatePeriodModel, (newObj) => {
  if(firstLoadingDone)
    apiReportRate('behavior')
})
watch(timelineRatePeriodModel, (newObj) => {
  if(firstLoadingDone)
    apiReportRate('timeline')
})

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    frequencyRateLineData.value = {
      labels: ['2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01'],
      datasets: [
        {
          label: '참여율           ',
          borderColor: '#3C50E0',
          backgroundColor: '#3C50E0',
          borderWidth:2,
          pointBorderWidth:3,
          pointHoverBorderWidth:5,
          data: [10, 22, 12, 80, 40, 60, 10],
        },
        {
          label: 'audit 참여율',
          borderColor: '#A155B9',
          backgroundColor: '#A155B9',
          borderWidth:2,
          pointBorderWidth:3,
          pointHoverBorderWidth:5,
          data: [40, 60, 35, 20, 5, 20, 40]
        }
      ]
    }
    behaviorRateLineData.value = {
      labels: ['2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01'],
      datasets: [
        {
          label: '안전행동률           ',
          borderColor: '#3C50E0',
          backgroundColor: '#3C50E0',
          borderWidth:2,
          pointBorderWidth:3,
          pointHoverBorderWidth:5,
          data: [10, 22, 12, 80, 40, 60, 10],
        },
        {
          label: 'audit 안전행동률',
          borderColor: '#A155B9',
          backgroundColor: '#A155B9',
          borderWidth:2,
          pointBorderWidth:3,
          pointHoverBorderWidth:5,
          data: [40, 60, 35, 20, 5, 20, 40]
        }
      ]
    }
    timelineRateLineData.value = {
      labels: ['2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01'],
      datasets: [
        {
          label: '안전대화 실시율',
          borderColor: '#3C50E0',
          backgroundColor: '#3C50E0',
          borderWidth:2,
          pointBorderWidth:3,
          pointHoverBorderWidth:5,
          data: [10, 22, 12, 80, 40, 60, 10],
        }
      ]
    }
    behaviorStep1BarData.value = {
      labels: [
        '카테고리abcd 12',
        '카테고리 2',
        '카테고리 3',
        '카테고리 4',
        '카테고리 제목 들어감 5',
      ],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#3C50E0',
          data: [40, 20, 60, 80, 10]
        }
      ]
    }
    behaviorStep2BarData.value = {
      labels: [
        '관찰항목abcd 1',
        '관찰항목abcd 2',
        '관찰항목abcd test button 1234',
      ],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#3C50E0',
          data: [40, 20, 60]
        }
      ]
    }
    behaviorStep3BarData.value = {
      labels: [
        '세부항목abcd 1',
        '세부항목abcd 2',
        '세부항목abcd 3',
        '세부항목abcd 4',
      ],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#3C50E0',
          data: [40, 20, 60, 80]
        }
      ]
    }
    timelineBehaviorItems.value = [
      {
        action: '항목1',
        safetyTalk: '2',
        cheerTalk: '4'
      },
      {
        action: '항목1',
        safetyTalk: '2',
        cheerTalk: '4'
      },
      {
        action: '항목1',
        safetyTalk: '2',
        cheerTalk: '4'
      },
    ]
    memberActivityItems.value = [
      {
        author: '김상팔 / 부서 / 직책',
        monitoring: '2',
        safetyTalk: '4',
        submit: '2024-01-24 13:38:40'
      },
      {
        author: '김상팔 / 부서 / 직책',
        monitoring: '2',
        safetyTalk: '4',
        submit: '2024-01-24 13:38:40'
      },
      {
        author: '김상팔 / 부서 / 직책',
        monitoring: '2',
        safetyTalk: '4',
        submit: '2024-01-24 13:38:40'
      },
    ]
  }
  else {
    if(viewIdx) {
      let params = {
        idx: viewIdx
      }
      //모니터링정보
      BbsService.getBbsMonitoring(params).then(
        (response) => {
          //모니터링
          monitoringInfo.value = response.result
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
      //본인부서에 해당하는 모니터링, 부서, 맴버정보
      BbsService.getMonitoringFilter({}).then(
        (response) => {
          //모니터링필터
          let monitoringList = response.result.monitoringList
          _.forEach(monitoringList, function (item){
            monitoringSelect.value.items.push({name:item.monitoringTitle, value:item.idx})
            if(viewIdx == item.idx)
              monitoringModel.value = {name:item.monitoringTitle, value:item.idx}
          })
          //부서필터
          let deptList = response.result.deptList
          _.forEach(deptList, function (item){
            deptSelect.value.items.push({name:item.treeName, value:item.idx})
          })
          //직책필터
          let posList = response.result.posList
          _.forEach(posList, function (item){
            posSelect.value.items.push({name:item.treeName, value:item.idx})
          })
          //맴버필터
          let memberList = response.result.memberList
          _.forEach(memberList, function (item){
            memberSelect.value.items.push({name:item.memberName, value:item.idx})
          })
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
      //레포트 노출정보
      let companyIdx = localStorage.getItem('companyIdx')
      BbsService.getMonitoringReportDisplay({companyIdx:companyIdx}).then(
        (response) => {
          //모니터링 노출여부
          reportDisplayInfo.value = response.result
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }

    onClickDate('month')
    apiReportSummary()
    apiReportRate('')
    apiReportBehaviorRate('category','','')
    apiTimelineBehaviorList()
    apiTimelineMemberList()
  }
})

//function
function apiReportSummary(){
  let params = {
    startDate:startDate.value,
    endDate:endDate.value,
    memberIdx:memberModel.value['value'],
    deptIdx:deptModel.value['value'],
    posIdx:posModel.value['value'],
    shiftYn:shiftModel.value['value'],
    idx:monitoringModel.value['value'],
    isExclude:'N',
  }
  //summary
  BbsService.getBbsReportSummary(params).then(
    (response) => {
      periodAnswerCnt.value = response.result.periodAnswerCnt
      allAnswerCnt.value = response.result.allAnswerCnt
      periodAnswerPercent.value = response.result.periodAnswerPercent
      allAnswerPercent.value = response.result.allAnswerPercent
      timelineTy1Cnt.value = response.result.timelineTy1Cnt
      timelineTy1Percent.value = response.result.timelineTy1Percent
      timelineAllRate.value = response.result.timelineAllRate
      timelineTy1Rate.value = response.result.timelineTy1Rate
      timelineTy2Rate.value = response.result.timelineTy2Rate
      myTimelineTy1Cnt.value = response.result.myTimelineTy1Cnt
      myTimelineTy2Cnt.value = response.result.myTimelineTy2Cnt
      memberAnswerList.value = response.result.memberAnswerList
      _.forEach(memberAnswerList.value, (val, key)=>{
        val.activityCnt = val.allAnswerCnt+val.timelineTy1Cnt+val.timelineTy2Cnt
      })
      memberAnswerList.value = _.sortBy(memberAnswerList.value, 'activityCnt')
      memberAnswerListReverse.value = _.sortBy(memberAnswerList.value, 'activityCnt').reverse()
      memberAnswerList.value = _.slice(memberAnswerList.value, 0, 3)
      memberAnswerListReverse.value = _.slice(memberAnswerListReverse.value, 0, 3)

      firstLoadingDone = true
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function apiReportRate(type){
  let typeParam = frequencyRatePeriodModel.value['value']
  if(type == 'behavior')
    typeParam = behaviorRatePeriodModel.value['value']
  else if(type == 'timeline')
    typeParam = timelineRatePeriodModel.value['value']
  let params = {
    startDate:startDate.value,
    endDate:endDate.value,
    memberIdx:memberModel.value['value'],
    deptIdx:deptModel.value['value'],
    posIdx:posModel.value['value'],
    shiftYn:shiftModel.value['value'],
    idx:monitoringModel.value['value'],
    periodRateType:typeParam,
    isExclude:'N',
  }
  //참여율정보
  BbsService.getBbsReportRate(params).then(
    (response) => {
      if(type == '' || type == 'frequency'){
        let labels = []
        let normalData = []
        let auditData = []
        _.forEach(response.result, (val, key)=>{
          if(frequencyRatePeriodModel.value['value'] == 'week')
            labels.push(val.startDate)
          else
            labels.push(val.answerDate)
          if(val.answerCntNormal == 0 || val.periodCntNormal == 0)
            normalData.push(0)
          else
            normalData.push(val.answerCntNormal / val.periodCntNormal * 100)
          if(val.answerCntAudit == 0 || val.periodCntAudit == 0)
            auditData.push(0)
          else
            auditData.push(val.answerCntAudit / val.periodCntAudit * 100)
        })
        frequencyRateLineData.value = {
          labels: labels,
          datasets: [
            {
              label: '참여율           ',
              borderColor: '#3C50E0',
              backgroundColor: '#3C50E0',
              borderWidth:2,
              pointBorderWidth:3,
              pointHoverBorderWidth:5,
              data: normalData,
            },
            {
              label: 'audit 참여율',
              borderColor: '#A155B9',
              backgroundColor: '#A155B9',
              borderWidth:2,
              pointBorderWidth:3,
              pointHoverBorderWidth:5,
              data: auditData
            }
          ]
        }
      }
      if(type == '' || type == 'behavior'){
        let labels = []
        let normalData = []
        let auditData = []
        _.forEach(response.result, (val, key)=>{
          if(behaviorRatePeriodModel.value['value'] == 'week')
            labels.push(val.startDate)
          else
            labels.push(val.answerDate)
          normalData.push(Math.round(val.scoreNormal))
          auditData.push(Math.round(val.scoreAudit))
        })
        behaviorRateLineData.value = {
          labels: labels,
          datasets: [
            {
              label: '안전행동률           ',
              borderColor: '#3C50E0',
              backgroundColor: '#3C50E0',
              borderWidth:2,
              pointBorderWidth:3,
              pointHoverBorderWidth:5,
              data: normalData,
            },
            {
              label: 'audit 안전행동률',
              borderColor: '#A155B9',
              backgroundColor: '#A155B9',
              borderWidth:2,
              pointBorderWidth:3,
              pointHoverBorderWidth:5,
              data: auditData
            }
          ]
        }
      }
      if(type == '' || type == 'timeline'){
        let labels = []
        let normalData = []
        let auditData = []
        _.forEach(response.result, (val, key)=>{
          if(timelineRatePeriodModel.value['value'] == 'week')
            labels.push(val.startDate)
          else
            labels.push(val.answerDate)
          if(val.timelineCntNormal == 0 || val.periodCntNormal == 0)
            normalData.push(0)
          else
            normalData.push(val.timelineCntNormal / val.periodCntNormal * 100)
          if(val.timelienCntAudit == 0 || val.periodCntAudit == 0)
            auditData.push(0)
          else
            auditData.push(val.timelienCntAudit / val.periodCntAudit * 100)
        })
        timelineRateLineData.value = {
          labels: labels,
          datasets: [
            {
              label: '안전대화 실시율',
              borderColor: '#3C50E0',
              backgroundColor: '#3C50E0',
              borderWidth:2,
              pointBorderWidth:3,
              pointHoverBorderWidth:5,
              data: normalData
            }
          ]
        }
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function apiReportBehaviorRate(type, idx, idx2){
  let params = {
    startDate:startDate.value,
    endDate:endDate.value,
    memberIdx:memberModel.value['value'],
    deptIdx:deptModel.value['value'],
    posIdx:posModel.value['value'],
    shiftYn:shiftModel.value['value'],
    idx:monitoringModel.value['value'],
    rateType:type,
    categoryIdx:idx,
    behaviorIdx:idx2,
    isExclude:'N',
  }

  //bbs 리포트 카테고리별 통계 (카테고리별,관찰항목별,세부관찰항목별)
  BbsService.getMonitoringStepRate(params).then(
    (response) => {
      let labels = []
      let labelsObj = []
      let normalData = []
      _.forEach(response.result, (val, key)=>{
        if(type == 'category') {
          labels.push(val.categoryTitle)
          labelsObj.push({categoryIdx:val.categoryIdx, label:val.categoryTitle})
        }
        else if(type == 'behavior') {
          labels.push(val.behaviorTitle)
          labelsObj.push({categoryIdx:val.categoryIdx, behaviorIdx:val.behaviorIdx, label:val.behaviorTitle})
        }
        if(type == 'comment') {
          labels.push(val.commentTitle)
          labelsObj.push({commentIdx:val.commentIdx, label:val.commentTitle})
        }
        normalData.push(val.score)
      })
      let barData = {
        labels: labels,
        datasets: [
          {
            label: '안전행동률',
            backgroundColor: '#3C50E0',
            data: normalData
          }
        ],
        labelsObj:labelsObj
      }
      if(type == 'category') {
        behaviorStep1BarData.value = barData
        behaviorStep2BarData.value = {}
        behaviorStep3BarData.value = {}
      }
      else if(type == 'behavior') {
        behaviorStep2BarData.value = barData
        behaviorStep3BarData.value = {}
      }
      else if(type == 'comment')
        behaviorStep3BarData.value = barData
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function apiTimelineBehaviorList(){
  let params = {
    startDate:startDate.value,
    endDate:endDate.value,
    memberIdx:memberModel.value['value'],
    deptIdx:deptModel.value['value'],
    posIdx:posModel.value['value'],
    shiftYn:shiftModel.value['value'],
    idx:monitoringModel.value['value'],
    isExclude:'N',
  }
  searchParams = params
  //bbs 리포트 안전대화 활동상세
  BbsService.getMonitoringTimelineBehaviorCnt(params).then(
    (response) => {
      let tempItems = []
      _.forEach(response.result, (val, key)=>{
        tempItems.push({action:val.behaviorTitle, safetyTalk:val.timelineTy1Cnt, cheerTalk:val.timelineTy2Cnt, behaviorIdx:val.behaviorIdx})
      })
      timelineBehaviorItems.value = tempItems
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function apiTimelineMemberList(){
  let params = {
    startDate:startDate.value,
    endDate:endDate.value,
    memberIdx:memberModel.value['value'],
    deptIdx:deptModel.value['value'],
    posIdx:posModel.value['value'],
    shiftYn:shiftModel.value['value'],
    idx:monitoringModel.value['value'],
    isExclude:'N',
  }
  searchParams = params
  //bbs 리포트 개인별 활동상세
  BbsService.getMonitoringAnswerMemberCnt(params).then(
    (response) => {
      let tempItems = []
      let tempAnswerCnt = 0
      let tempPeriodCnt = 0
      _.forEach(response.result, (val, key)=>{
        tempItems.push({
          author:val.memberName+'/'+val.deptName+'/'+val.posName,
          monitoring:val.answerCnt,
          safetyTalk:val.timelineCnt,
          submit:dayjs(val.regDate).format('YYYY-MM-DD') == 'Invalid Date' ? '' : dayjs(val.regDate).format('YYYY-MM-DD'),
          idx:val.idx
        })
        tempAnswerCnt += val.answerCnt
        tempPeriodCnt += val.periodCnt
      })
      memberActivityItems.value = tempItems
      behaviorStep3Cnt.value = tempAnswerCnt
      if(tempAnswerCnt == 0 || tempPeriodCnt == 0)
        behaviorStep3Rate.value = 0
      else
        behaviorStep3Rate.value = Math.round(tempAnswerCnt / tempPeriodCnt * 100)
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickTimelineBehaviorDialog(idx){
  searchParams.behaviorIdx = idx
  storeRef.popupParams.value = searchParams
  timelineBehaviorDialogModel.value = true
}

function onClickDate(type){
  let end = dayjs().endOf('month').format('YYYY-MM-DD')
  let start = dayjs().startOf('month').format('YYYY-MM-DD')
  if(type == 'quarter') {
    end = dayjs().endOf('quarter').format('YYYY-MM-DD')
    start = dayjs().startOf('quarter').format('YYYY-MM-DD')
  }
  else if(type == 'year') {
    end = dayjs().endOf('year').format('YYYY-MM-DD')
    start = dayjs().startOf('year').format('YYYY-MM-DD')
  }

  startDate.value = start.toString()
  endDate.value = end.toString()
}

//리포트 오브젝트 노출여부 설정
function onClickUpdateDisplay(type, value){
  //레포트 노출정보
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {companyIdx:companyIdx}
  params[type] = value=='Y'?'N':'Y'
  BbsService.updateMonitoringReportDisplay(params).then(
    (response) => {
      //모니터링 노출여부
      reportDisplayInfo.value = response.result
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

//필터 메뉴 열고 닫기
const filterDiv1 = ref('off')
function filterOpen1(){
  if(filterDiv1.value == 'off')
    filterDiv1.value = 'on'
  else
    filterDiv1.value = 'off'
}

//유효성검사 리프래쉬
function confirmValidation(){
  apiReportSummary()
  apiReportRate('')
  apiReportBehaviorRate('category','','')
  apiTimelineBehaviorList()
  apiTimelineMemberList()
}
</script>


<style lang="scss" >
</style>
