import api from "./api";
import apiMP from "./api";
import Utils from "@/common/Utils";

class BbsService {
  getBbs(params) {
    return api
      .post("/bbs/getBbs", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getBbsList(params) {
    return api
      .post("/bbs/getBbsList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  insertBbs(params) {
    let apiUrl = '/bbs/insertBbs'
    if(params['idx'])
      apiUrl = '/bbs/updateBbs'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  deleteBbs(params) {
    let apiUrl = '/bbs/deleteBbs'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  updateColumnBbs(params) {
    let apiUrl = '/bbs/updateColumnBbs'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  updateMonitoringTarget(params) {
    let apiUrl = '/bbs/updateMonitoringTarget'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  copyBbs(params) {
    let apiUrl = '/bbs/copyBbs'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBehavior(params) {
    return api
      .post("/bbs/getBehavior", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBehaviorList(params) {
    return api
      .post("/bbs/getBehaviorList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertBehavior(params) {
    let apiUrl = '/bbs/insertBehavior'
    if(params['idx'])
      apiUrl = '/bbs/updateBehavior'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  deleteBehavior(params) {
    let apiUrl = '/bbs/deleteBehavior'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBehaviorCategory(params) {
    return api
      .post("/bbs/getBehaviorCategory", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBehaviorCategoryList(params) {
    return api
      .post("/bbs/getBehaviorCategoryList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertBehaviorCategory(params) {
    let apiUrl = '/bbs/insertBehaviorCategory'
    if(params['idx'])
      apiUrl = '/bbs/updateBehaviorCategory'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  deleteBehaviorCategory(params) {
    let apiUrl = '/bbs/deleteBehaviorCategory'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  copyBehavior(params) {
    let apiUrl = '/bbs/copyBehavior'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getMonitoringPlaceList(params) {
    return api
      .post("/bbs/getMonitoringPlaceList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertMonitoringPlace(params) {
    let apiUrl = '/bbs/insertMonitoringPlace'
    if(params['idx'])
      apiUrl = '/bbs/updateMonitoringPlace'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  deleteMonitoringPlace(params) {
    let apiUrl = '/bbs/deleteMonitoringPlace'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getMonitoringPeriodList(params) {
    let apiUrl = '/bbs/getMonitoringPeriodList'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBookmarkList(params) {
    let apiUrl = '/bbs/getBookmarkList'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertBookmark(params) {
    let apiUrl = '/bbs/insertBookmark'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  deleteBookmark(params) {
    let apiUrl = '/bbs/deleteBookmark'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getBbsMonitoring(params) {
    return api
      .post("/bbs/getBbsMonitoring", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getBbsMonitoringSummary(params) {
    return api
      .post("/bbs/getBbsMonitoringSummary", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  insertAnswer(params) {
    return api
      .post("/bbs/insertAnswer", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getAnswerList(params) {
    return api
      .post("/bbs/getAnswerList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getMonitoringFilter(params) {
    return api
      .post("/bbs/getMonitoringFilter", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //bbs 리포트 요약정보
  getBbsReportSummary(params) {
    return api
      .post("/bbs/getBbsReportSummary", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //bbs 리포트 참여율정보 (참여율, 안전행동율, 안전대화참여율)
  getBbsReportRate(params) {
    return api
      .post("/bbs/getBbsReportRate", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //bbs 리포트 카테고리별 통계 (카테고리별,관찰항목별,세부관찰항목별)
  getMonitoringStepRate(params) {
    return api
      .post("/bbs/getMonitoringStepRate", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //bbs 리포트 안전대화 활동상세
  getMonitoringTimelineBehaviorCnt(params) {
    return api
      .post("/bbs/getMonitoringTimelineBehaviorCnt", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //bbs 리포트 개인별 활동상세
  getMonitoringAnswerMemberCnt(params) {
    return api
      .post("/bbs/getMonitoringAnswerMemberCnt", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //bbs 리포트 개인별,모니터링순차별 활동상세
  getMonitoringPeriodMemberCnt(params) {
    return api
      .post("/bbs/getMonitoringPeriodMemberCnt", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //리포트 노출여부
  getMonitoringReportDisplay(params) {
    return api
      .post("/bbs/getMonitoringReportDisplay", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //리포트 노출여부 수정
  updateMonitoringReportDisplay(params) {
    return api
      .post("/bbs/updateMonitoringReportDisplay", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //데이터유효성 변경
  updateAnswerExclude(params) {
    return api
      .post("/bbs/updateAnswerExclude", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  //데이터유효성변경 로그
  getAnswerExcludeLogList(params) {
    return api
      .post("/bbs/getAnswerExcludeLogList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new BbsService();
