<template>
  <div class="lnb">

    <v-list v-model:selected="selected">
      <v-list-item
        v-for="item in lnbItems"
        :title="item.title"
        :value="item.value"
        :class="item.class"
        :disabled="item.value==selected || item.disabled"
        v-show="item.show"
        @click="onClickMenu(item.action)"
      />
    </v-list>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from 'vue'
import utils from "@/common/Utils"
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const selected = ref('lnb1')
const lnbItems = ref([
  {
    title: 'Safety Calender',
    value: 'lnb1',
    class: 'lnb-n1',
    action: '/safetyCalender',
    show: true
  },
  {
    title: '행동기반 안전관리',
    value: 'lnb2',
    class: 'lnb-n2',
    action: '/actionManagement',
    show: true
  },
  {
    title: '안전리더십',
    value: 'lnb3',
    class: 'lnb-n3',
    action: '/safetyLeadership',
    show: true,
    disabled: true
  },
  {
    title: '통합보고서',
    value: 'lnb4',
    class: 'lnb-n4',
    action: '/totalReport',
    show: true
  },
  {
    title: 'My Board',
    value: 'lnb5',
    class: 'lnb-n5',
    action: '/myBoard',
    show: true
  },
  {
    title: '조직관리',
    value: 'lnb6',
    class: 'lnb-n6',
    action: '/organizationalManagement/1/list',
    show: true
  }
])

//watch
watch(storeRef.user, async (newData, oldData) => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (storeRef.user.value === null) {
      storeRef.user.value = user
    }

    if(user.moduleCalender === 'N' && (user.roles === 'ADMIN3' || user.roles === 'USER'))
      lnbItems.value[0].show = false
    if(user.moduleBbs === 'N' && ( user.roles === 'ADMIN3' || user.roles === 'USER'))
      lnbItems.value[1].show = false
    if(user.moduleLeadership === 'N' && ( user.roles === 'ADMIN3' || user.roles === 'USER'))
      lnbItems.value[2].show = false
    if(user.moduleReport === 'N' && ( user.roles === 'ADMIN3' || user.roles === 'USER'))
      lnbItems.value[3].show = false
    if(user.moduleBoard === 'N' && ( user.roles === 'ADMIN3' || user.roles === 'USER'))
      lnbItems.value[4].show = false
    if(user.roles === 'USER')
      lnbItems.value[5].show = false
  },
  {
    deep: true,
    immediate:true
  }
)

//hook
onUpdated (() => {
  const user = JSON.parse(localStorage.getItem('user'))
  if(storeRef.user.value === null){
    storeRef.user.value = user
  }
})

//function
function onClickMenu(page) {
  if(page != '/safetyLeadership')
    utils.movePage(page)
}
</script>

<style lang="scss">
.lnb{
  z-index:2000;
  position:fixed;
  top:8rem;
  left:0;
  width:18rem;
  height:8rem;
  .v-list{
    background:transparent;
    padding:8.5rem 3rem;
  }
  .v-list-item{
    &:before{
      content:'';
      display:block;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-position:center 1.5rem;
      background-repeat:no-repeat;
      background-size:3.2rem 3.2rem;
      border: 1px solid transparent;
    }
    &.v-list-item--disabled:before{
      border: 1px solid #E2E8F0;
      border-radius:1.5rem !important;
    }
    &.v-list-item--disabled:not(.v-list-item--link) .v-list-item__overlay{
      opacity:0;
    }
    &.v-list-item--disabled,
    &.v-list-item[aria-haspopup=menu][aria-expanded=true]{
      position:relative;
      border-radius:1.5rem !important;
      background-color: var( --txt-white);
      box-shadow: 0 .6rem 1.5rem 0 rgba(69, 109, 187, 0.10);
      opacity:1;
    }
    &.lnb-n1:before{
      background-image: url("@/assets/img/ico-lnb1.svg");
    }
    &.v-list-item--disabled.lnb-n1:before{
      background-image: url("@/assets/img/ico-lnb1-s.svg");
    }
    &.lnb-n2:before{
      background-image: url("@/assets/img/ico-lnb2.svg");
    }
    &.v-list-item--disabled.lnb-n2:before{
      background-image: url("@/assets/img/ico-lnb2-s.svg");
    }
    &.lnb-n3:before{
      background-image: url("@/assets/img/ico-lnb3.svg");
    }
    &.v-list-item--disabled.lnb-n3:before{
      background-image: url("@/assets/img/ico-lnb3-s.svg");
    }
    &.lnb-n4:before{
      background-image: url("@/assets/img/ico-lnb4.svg");
    }
    &.v-list-item--disabled.lnb-n4:before{
      background-image: url("@/assets/img/ico-lnb4-s.svg");
    }
    &.lnb-n5:before{
      background-image: url("@/assets/img/ico-lnb5.svg");
    }
    &.v-list-item--disabled.lnb-n5:before{
      background-image: url("@/assets/img/ico-lnb5-s.svg");
    }
    &.lnb-n6:before{
      background-image: url("@/assets/img/ico-lnb6.svg");
    }
    &.v-list-item--disabled.lnb-n6:before{
      background-image: url("@/assets/img/ico-lnb6-s.svg");
    }

  }
  .v-list-item + .v-list-item{margin-top:.5rem}
  .v-list-item__overlay{
    border-radius:1.5rem;
  }
  .v-list-item-title{
    font-size:1.4rem;
    line-height:1.7rem;
    word-break:keep-all;
    white-space: normal;
    text-align:center;
    color:var(--lnb-txt);
    font-weight:700;
  }
  .v-list-item--disabled .v-list-item-title{
    color:var(--lnb-txt-active);
  }
  .v-list-item--density-default.v-list-item--one-line{
    padding:5.5rem 1rem 1.8rem ;
  }
  .v-list-item--disabled > .v-list-item__overlay,
  .v-list-item[aria-haspopup=menu][aria-expanded=true] > .v-list-item__overlay{
    opacity:0;
  }
  //안전리더십 오픈후 주석처리
  .v-list-item.lnb-n3{
    &.v-list-item--disabled:before{
      border:0;
    }
    &.v-list-item--disabled,
    &.v-list-item[aria-haspopup=menu][aria-expanded=true]{
      background-color:transparent;
      box-shadow: none;
    }
    &.v-list-item--disabled:before{
      background-image: url("@/assets/img/ico-lnb3.svg");
      opacity:.4;
    }
    .v-list-item-title{
      color:var(--lnb-txt);
      opacity: .4;
    }
    .v-list-item__content{
      position:relative;
      overflow:visible;
      &:before{
        content:'서비스준비중';
        box-sizing: border-box;
        display:block;
        position:absolute;
        top:-2.2rem;
        left:calc(50% - 3.7rem);
        width:7.4rem;
        height:2rem;
        border-radius:1rem;
        line-height:2rem;
        font-size:1.1rem;
        font-weight:700;
        text-align:center;
        color: var(--color-white);
        background:var(--color-black-op2);
      }
    }
  }

}
@media (max-width: 853px){
  .lnb{
    top:auto;
    bottom:0;
    width:100%;
    height:auto;
    //min-height:7rem;
    background:var(--color-white);
    border-top:1px solid var(--tab-line);
    box-shadow: 0 -.4rem 1rem 0 rgba(0, 0, 0, 0.07);

    .v-list{
      padding:0;
      display:flex;
      justify-content:center;
      .v-list-item{
        flex:1 1 0;
        &:before{
          background-position:center .9rem;
          background-size:2rem 2rem;
        }
        &.lnb-n6{
          display:none;
        }
      }
      .v-list-item + .v-list-item{
        margin:0;
      }
      .v-list-item.v-list-item--disabled,
      .v-list-item.v-list-item[aria-haspopup=menu][aria-expanded=true]{
        box-shadow:none;
      }
      .v-list-item.v-list-item--disabled:before{
        border:0;
      }
      .v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line{
        padding-inline:.5rem;
      }
      .v-list-item--density-default.v-list-item--one-line{
        padding:3.2rem 1rem .9rem
      }
      .v-list-item-title{
        font-size:1.2rem;
        line-height:1.4rem;
        font-weight:500;
        color:var(--color-gray3) ;
      }
    }
  }
}
</style>
